import { EComponentSize, Label } from '@hyperclap/ui';
import React from 'react';

import { IUser } from '@typings';

import s from './PersonalInfo.scss';

interface IPersonalInfoProps {
    currentUser: IUser
}

export const PersonalInfo = (props: IPersonalInfoProps) => {
    const { currentUser } = props;

    return (
        <Label
            className={s.root}
            caption={`ID: ${currentUser.id}`}
            size={EComponentSize.SMALL}
        />
    );
};
