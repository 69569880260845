import React from 'react';

import { ISticker } from '@typings';

import s from './StickerOverlay.scss';


interface StickerOverlayProps {
    sticker: ISticker;
    soundEnabled?: boolean;
    onSoundButtonClick?: () => void;
}

export const StickerOverlay = (props: StickerOverlayProps) => {
    const {
        sticker,
    } = props;

    return (
        <div className={s.stickerOverlay}>
            <div className={s.stickerOverlayContent}>
                <div className={s.stickerOverlayContentStickerName}>
                    {sticker.name}
                </div>
            </div>
        </div>
    );
};
