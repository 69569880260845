import {
    Button,
    Column,
    Divider,
    Hint,
    Label,
    Row,
    TextArea,
    TextField,
    ERowAlignment,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';

import { ImageMemeAlertsBanner } from '@assets/images/png';
import { IconCopyLine, IconImportLine, IconTrashLine } from '@assets/images/svg';
import {
    copyToClipboard,
    DISCORD_LINK_VALIDATION_REGEXP,
    TELEGRAM_LINK_VALIDATION_REGEXP,
    TWITCH_LINK_VALIDATION_REGEXP,
    TWITTER_LINK_VALIDATION_REGEXP,
    YOUTUBE_LINK_VALIDATION_REGEXP,
} from '@common';
import { Avatar } from '@components';
import { NotificationType } from '@contexts';
import { useAppContext, useNotifications } from '@hooks';


import { BackgroundLoader } from './components';
import s from './PageSettings.scss';

export const PageSettings = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            streamer: {
                page: {
                    channelAvatarImageData,
                    channelBgImageData,
                    isChannelAvatarCleared,
                    isChannelBgCleared,
                    updateChannel,
                    updateChannelAvatar,
                    updateChannelBg,
                    cleanChannelBg,
                    cleanChannelAvatar,
                    onChangeChannelAvatar,
                    onCleanAvatarImage,
                },
            },
        },
        translation: { t },
    } = app;

    const { notify } = useNotifications();

    const maBannerRef = useRef<HTMLAnchorElement>(null);
    const maAvatarRef = useRef<HTMLInputElement>(null);

    const [name, setName] = useState('');
    const [link, setLink] = useState('');
    const [backgroundMode, setBackgroundMode] = useState(currentUser?.channel.backgroundMode ?? 0);
    const [twitchLink, setTwitchLink] = useState('');
    const [twitchLinkValid, setTwitchLinkValid] = useState(false);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [youtubeLinkValid, setYoutubeLinkValid] = useState(false);
    const [telegramLink, setTelegramLink] = useState('');
    const [telegramLinkValid, setTelegramLinkValid] = useState(false);
    const [twitterLink, setTwitterLink] = useState('');
    const [twitterLinkValid, setTwitterLinkValid] = useState(false);
    const [discordLink, setDiscordLink] = useState('');
    const [discordLinkValid, setDiscordLinkValid] = useState(false);
    const [paywallText, setPaywallText] = useState<string>('');

    const onChannelLinkFieldAction = () => {
        void copyToClipboard(`${location.origin}/${link}`);
        notify('Ссылка скопирована в буфер обмена', NotificationType.SUCCESS);
    };

    const onOpenChannelLinkCLick = () => {
        window.open(`${location.origin}/${link?.toString()}`, '_blank');
    };

    const onSaveLinkClick = () => {
        void updateChannel({
            link: link?.toString(),
        });
    };

    const onSaveCommonDataClick = () => {
        updateChannel({
            name: name?.toString(),
            link: link?.toString(),
            backgroundMode,
            twitchLink: twitchLink?.toString(),
            youtubeLink: youtubeLink?.toString(),
            telegramLink: telegramLink?.toString(),
            discordLink: discordLink?.toString(),
            twitterLink: twitterLink?.toString(),
            paywallText: paywallText?.toString(),
        }).then(() => {
            if (channelAvatarImageData) {
                void updateChannelAvatar();
            }

            if (channelBgImageData) {
                void updateChannelBg();
            }

            if (isChannelBgCleared) {
                cleanChannelBg();
            }

            if (isChannelAvatarCleared) {
                cleanChannelAvatar();
            }
        });
    };

    const onCleanAvatarImageClick = () => {
        if (maAvatarRef.current) {
            maAvatarRef.current.value = '';
        }

        onCleanAvatarImage();
    };

    useEffect(() => {
        setTwitchLinkValid(!twitchLink || TWITCH_LINK_VALIDATION_REGEXP.test(twitchLink));
    }, [twitchLink]);

    useEffect(() => {
        setYoutubeLinkValid(!youtubeLink || YOUTUBE_LINK_VALIDATION_REGEXP.test(youtubeLink));
    }, [youtubeLink]);

    useEffect(() => {
        setTelegramLinkValid(!telegramLink || TELEGRAM_LINK_VALIDATION_REGEXP.test(telegramLink));
    }, [telegramLink]);

    useEffect(() => {
        setTwitterLinkValid(!twitterLink || TWITTER_LINK_VALIDATION_REGEXP.test(twitterLink));
    }, [twitterLink]);

    useEffect(() => {
        setDiscordLinkValid(!discordLink || DISCORD_LINK_VALIDATION_REGEXP.test(discordLink));
    }, [discordLink]);

    useEffect(() => {
        if (currentUser?.channel) {
            setName(currentUser?.channel.name);
            setLink(currentUser?.channel.link);
            setBackgroundMode(currentUser?.channel.backgroundMode ?? 0);
            setTwitchLink(currentUser?.channel.twitchLink);
            setYoutubeLink(currentUser?.channel.youtubeLink);
            setTelegramLink(currentUser?.channel.telegramLink);
            setTwitterLink(currentUser?.channel.twitterLink);
            setDiscordLink(currentUser?.channel.discordLink);
            setPaywallText(currentUser?.channel.paywallText);
        }
    }, [currentUser?.channel]);

    return (
        <Row columnGap={20} margin={{ top: 15, bottom: 50 }} alignment={ERowAlignment.TOP}>
            <Column width={510} rowGap={10}>
                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.page.channel-link-section')} size={EComponentSize.LARGE}/>

                <Row>
                    <TextField
                        caption={t('streamer-settings.page.channel-link')}
                        defaultValue={currentUser?.channel.link}
                        prefix={`${location.origin}/`}
                        actions={[
                            { name: 'copy', icon: <IconCopyLine/> },
                        ]}
                        width={410}
                        onAction={onChannelLinkFieldAction}
                        onChanged={setLink}
                    />
                </Row>

                <Row columnGap={15}>
                    <Button
                        color={EComponentColor.SUCCESS}
                        className={s.pageConfigButton}
                        caption={t('common.open')}
                        width={100}
                        onClick={onOpenChannelLinkCLick}
                    />
                    <Button
                        color={EComponentColor.ACCENT}
                        className={s.pageConfigButton}
                        caption={t('common.save')}
                        width={160}
                        onClick={onSaveLinkClick}
                    />
                </Row>

                <Divider margin={{ top: 20, bottom: 5 }} width={510}/>

                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.page.page-settings-section')} size={EComponentSize.LARGE}/>

                <Row margin={{ bottom: 10 }}>
                    <TextField
                        caption={t('streamer-settings.page.channel-name')}
                        defaultValue={currentUser?.channel?.name || currentUser?.username}
                        maxLength={32}
                        width={255}
                        onChanged={setName}
                    />
                </Row>

                <Row columnGap={15} margin={{ bottom: 10 }}>
                    <Column>
                        <Label padding={{ bottom: 10, top: 0 }} caption={t('streamer-settings.page.avatar')} size={EComponentSize.MEDIUM}/>
                        <Avatar
                            className={s.pageConfigAvatar}
                            source={!isChannelAvatarCleared
                                ? channelAvatarImageData || currentUser?.channel?.avatarUrl
                                : ''
                            }
                            width={100}
                            height={100}
                            inputRef={maAvatarRef}
                            withBorder
                            changeAllowed
                            showUploadWhenEmpty
                            onChanged={onChangeChannelAvatar}
                        />
                        <Button
                            caption={t('common.reset')}
                            icon={<IconTrashLine/>}
                            linkStyle
                            height={35}
                            padding={0}
                            width={80}
                            onClick={onCleanAvatarImageClick}
                        />
                    </Column>
                    <Column>
                        <BackgroundLoader
                            {...app}
                            onBackgroundModeChange={(value) => setBackgroundMode(value ? 1 : 0)}
                        />
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <Label padding={{ top: 0 }} caption={t('streamer-settings.page.paywall-text')} size={EComponentSize.MEDIUM}/>
                        <TextArea
                            className={s.pageConfigTextArea}
                            defaultValue={
                                currentUser?.channel?.paywallText ||
                                t('streamer-settings.page.paywall-text-default')
                            }
                            maxRows={3}
                            maxLength={90}
                            width={410}
                            onChange={(val) => setPaywallText(val?.toString() ?? '')}
                        />
                        <Label
                            caption={`${t('common.symbols')}: ${paywallText?.length ?? 0} / ${90}`}
                            muted
                            padding={{ top: 5 }} size={EComponentSize.SMALL}
                        />
                    </Column>
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <TextField
                        caption={t('streamer-settings.page.twitch-link')}
                        type={'url'}
                        defaultValue={currentUser?.channel?.twitchLink}
                        placeholder={'https://twitch.com/'}
                        hasError={!twitchLinkValid}
                        errorMessage={t('streamer-settings.page.twitch-link-error-text')}
                        width={410}
                        onChanged={setTwitchLink}
                    />
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <TextField
                        caption={t('streamer-settings.page.youtube-link')}
                        type={'url'}
                        defaultValue={currentUser?.channel?.youtubeLink}
                        placeholder={'https://youtube.com/'}
                        hasError={!youtubeLinkValid}
                        width={410}
                        errorMessage={t('streamer-settings.page.youtube-link-error-text')}
                        onChanged={setYoutubeLink}
                    />
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <TextField
                        caption={t('streamer-settings.page.telegram-link')}
                        type={'url'}
                        defaultValue={currentUser?.channel?.telegramLink}
                        placeholder={'https://t.me/'}
                        hasError={!telegramLinkValid}
                        width={410}
                        errorMessage={t('streamer-settings.page.telegram-link-error-text')}
                        onChanged={setTelegramLink}
                    />
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <TextField
                        caption={t('streamer-settings.page.twitter-link')}
                        type={'url'}
                        defaultValue={currentUser?.channel?.twitterLink}
                        placeholder={'https://twitter.com/'}
                        hasError={!twitterLinkValid}
                        width={410}
                        errorMessage={t('streamer-settings.page.twitter-link-error-text')}
                        onChanged={setTwitterLink}
                    />
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <TextField
                        caption={t('streamer-settings.page.discord-link')}
                        type={'url'}
                        defaultValue={currentUser?.channel?.discordLink}
                        placeholder={'https://discord.gg/'}
                        hasError={!discordLinkValid}
                        width={410}
                        errorMessage={t('streamer-settings.page.discord-link-error-text')}
                        onChanged={setDiscordLink}
                    />
                </Row>

                <Row>
                    <Button
                        className={s.pageConfigButton}
                        disabled={!twitchLinkValid || !youtubeLinkValid || !telegramLinkValid}
                        caption={t('common.save')}
                        color={EComponentColor.ACCENT}
                        width={160}
                        onClick={onSaveCommonDataClick}
                    />
                </Row>
            </Column>
            <Column grow>
                <Row columnGap={20} className={s.bannerRow}>
                    <img
                        alt={''}
                        className={s.bannerRowImage}
                        src={ImageMemeAlertsBanner}
                        onLoad={(e) => {
                            if (maBannerRef.current) {
                                maBannerRef.current.href = e.currentTarget.src;
                            }
                        }}
                    />
                    <a
                        href=""
                        download={'ma-button.png'}
                        className={s.bannerRowDownload}
                        ref={maBannerRef}
                    >
                        <IconImportLine/>
                    </a>
                </Row>
                <Hint margin={{ top: 5 }} muted textSize={EComponentSize.SMALL}>
                    {'Разместите ссылку на вашу страницу MemeAlerts\n'}
                    {'под стримом, чтобы зрители переходили на неё\nи отправляли вам стикеры.'}
                </Hint>
            </Column>
        </Row>
    );
};
