import { IBuyCurrencyParams, IHasPaymentsParams } from '@typings';

import { baseApi } from './base.api';

export const paymentsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getPaymentUrl: build.query<{ url: string }, IBuyCurrencyParams>({
                query: (buyCurrency: IBuyCurrencyParams) => ({
                    url: `payments/paywall`,
                    method: 'POST',
                    body: buyCurrency,
                }),
            }),
            hasPayments: build.query<boolean, IHasPaymentsParams>({
                query: (body: IHasPaymentsParams) => ({
                    url: `payments/has-payments`,
                    method: 'POST',
                    body,
                }),
            }),
        }),
    });
