/* eslint max-len: off */
import {
    IAvatarAsset,
    IAvatarAssetColorsMapPermanent,
    IAvatarAssetColorsMapVariable,
    IAvatarAssetColorsMapVariableWithTestColors,
} from '@typings';

import { IGetCombinedSvgOptions } from './types';
import { faceEmotionToAsset } from '../faceEmotionToAsset';
import { getShorthandColor } from '../getShorthandColor';
import { isShorthandColorPossible } from '../isShorthandColorPossible';


const SOURCE_BODY_COLOR = '#e6e7e8';
const ASSETS_CACHE: Record<string, string> = {};
const FACE_Z_INDEX = { zIndex: 17 };

const fetchAsset = async (url: string) => {
    if (ASSETS_CACHE[url]) {
        return ASSETS_CACHE[url];
    } else {
        const response = await fetch(url);
        const result = await response.text();

        ASSETS_CACHE[url] = result;

        return result;
    }
};

const injectToDefs = (originalSvg: string, injected: string) => {
    if (originalSvg.includes('<defs>')) {
        return originalSvg.replace(/<defs>([\s\S]+)<\/defs>/, `<defs>$1\n${injected}</defs>`);
    } else {
        return originalSvg.replace(/<svg([^>]*?)>/, `<svg$1>\n<defs>${injected}</defs>`);
    }
};

const processingSvg = ({
    originalSvg,
    colorsMap,
    variableColorsMap,
    skinColor,
}: {
    originalSvg: string;
    colorsMap: {
        variable: IAvatarAssetColorsMapVariable;
        permanent: IAvatarAssetColorsMapPermanent;
    };
    variableColorsMap?: IAvatarAssetColorsMapVariableWithTestColors;
    skinColor: string;
}) => {
    let patterns = '';

    if (colorsMap) {
        colorsMap.permanent.images.forEach((item, index) => {
            patterns += `
                <pattern id="pattern_permanent_${index}" width="${item.size.width}" height="${item.size.height}" patternUnits="userSpaceOnUse">
                    <image xlink:href="${item.image.url}" width="${item.size.width}" height="${item.size.height}"></image>
                </pattern>
            `;
        });

        variableColorsMap?.images.forEach((item, index) => {
            if (item.testImage) {
                patterns += `
                    <pattern id="pattern_variable_${index}" width="${item.testImageSize?.width}" height="${item.testImageSize?.height}" patternUnits="userSpaceOnUse">
                        <image xlink:href="${item.testImage.url}" width="${item.testImageSize?.width}" height="${item.testImageSize?.height}"></image>
                    </pattern>
                `;
            }
        });

        let modifiedSvg = injectToDefs(originalSvg, patterns);

        variableColorsMap?.images.forEach((item, index) => {
            if (item.item.color && item.testImage) {
                modifiedSvg = modifiedSvg.replace(
                    new RegExp(item.item.color, 'ig'),
                    `url(#pattern_variable_${index})`,
                );

                if (isShorthandColorPossible(item.item.color)) {
                    modifiedSvg = modifiedSvg.replace(
                        new RegExp(getShorthandColor(item.item.color), 'ig'),
                        `url(#pattern_variable_${index})`,
                    );
                }
            }
        });

        variableColorsMap?.colors.forEach((item) => {
            if (item.item.color && item.testColor) {
                modifiedSvg = modifiedSvg.replace(
                    new RegExp(item.item.color, 'ig'),
                    item.testColor,
                );

                if (isShorthandColorPossible(item.item.color)) {
                    modifiedSvg = modifiedSvg.replace(
                        new RegExp(getShorthandColor(item.item.color), 'ig'),
                        item.testColor,
                    );
                }
            }
        });

        colorsMap.permanent.images.forEach((item, index) => {
            if (item.srcColor) {
                modifiedSvg = modifiedSvg.replace(
                    new RegExp(item.srcColor, 'ig'),
                    `url(#pattern_permanent_${index})`,
                );

                if (isShorthandColorPossible(item.srcColor)) {
                    modifiedSvg = modifiedSvg.replace(
                        new RegExp(getShorthandColor(item.srcColor), 'ig'),
                        `url(#pattern_permanent_${index})`,
                    );
                }
            }
        });

        colorsMap.permanent.colors.forEach((item) => {
            if (item.srcColor) {
                modifiedSvg = modifiedSvg.replace(
                    new RegExp(item.srcColor, 'ig'),
                    item.dstColor,
                );

                if (isShorthandColorPossible(item.srcColor)) {
                    modifiedSvg = modifiedSvg.replace(
                        new RegExp(getShorthandColor(item.srcColor), 'ig'),
                        item.dstColor,
                    );
                }
            }
        });

        if (skinColor) {
            modifiedSvg = modifiedSvg.replace(new RegExp(SOURCE_BODY_COLOR, 'ig'), skinColor);
        }

        return modifiedSvg;
    } else {
        return originalSvg;
    }
};

export const getCombinedSvg = async (options: IGetCombinedSvgOptions) => {
    const {
        categories,
        assets,
        displayedAssets,
        layerPositions,
        hiddenLayerPositions,
        displayedFace,
        displayedFaceEmotion,
        eyeColor,
        skinColor,
        variableColorsMap,
        isHiddenBody,
    } = options;
    const bodyCategory = categories.find((item) => item.title.toLowerCase() === 'base');
    const bodyAsset = assets.find((item) => item.categoryId === bodyCategory?.id) as IAvatarAsset;

    const assetsList = await Promise.all(
        isHiddenBody ? [] : [bodyAsset]
            .concat([faceEmotionToAsset(displayedFaceEmotion, eyeColor)])
            .concat(displayedAssets)
            .map(async (asset) => {
                const layersList = await Promise.all(
                    asset.layers
                        .filter((layer) => !hiddenLayerPositions?.includes(layer.positionId))
                        .filter((layer) => Boolean(layer?.image?.url))
                        .map(async (layer) => {
                            const svg = await fetchAsset(layer.image.url);
                            const matched = svg.match(/<svg[^>]*>([\s\S]*?)<\/svg>/);
                            const svgInner = (matched?.[1] || '')
                                .replace(/cls-(\d+)/g, `cls-${layer.id}-$1`);

                            return {
                                layer,
                                svg: processingSvg({
                                    originalSvg: svgInner,
                                    colorsMap: asset.colorsMap,
                                    variableColorsMap: asset.id === displayedFaceEmotion?.id
                                        ? {
                                            colors: [{
                                                item: displayedFace.eyeColor,
                                                testColor: eyeColor.color,
                                            }],
                                            images: [],
                                        }
                                        : variableColorsMap?.find((map) => map.assetId === asset.id)?.colorMap,
                                    skinColor,
                                }),
                            };
                        }),
                );

                return {
                    asset,
                    layersList,
                };
            }),
    );

    const svgList = assetsList
        .map((item) => item.layersList)
        .flat()
        .sort((a, b) => {
            const positionA = a.layer.isFace
                ? FACE_Z_INDEX
                : layerPositions.find((item) => item.id === a.layer.positionId);
            const positionB = b.layer.isFace
                ? FACE_Z_INDEX
                : layerPositions.find((item) => item.id === b.layer.positionId);

            return positionA && positionB
                ? positionA?.zIndex - positionB?.zIndex
                : 0;
        })
        .map((item) => item.svg);

    return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1083 1000.44">
            ${svgList.join('')}
        </svg>
    `;
};
