import { Column, ITextFieldAction, ITextFieldMethods, LoggerColor, Row, SearchField, useLogger } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';

import { IconArrowRightLine, IconCloseLine, IconMagnifierLine } from '@assets/images/svg';
import { declensionUserSupporter, STICKER_SEARCH_QUERY_NAME } from '@common';
import { Avatar } from '@components';
import { TApp, useAppActions, useElementOnScreen } from '@hooks';


import s from './StreamerInfoBlock.scss';


enum SearchTextFieldAction {
    ENTER = 'enter',
    RESET = 'reset',
}

interface IStreamerInfoBlockProps extends TApp {}

export const StreamerInfoBlock = (props: IStreamerInfoBlockProps) => {
    const logger = useLogger({ target: StreamerInfoBlock.name, showTimestamp: true });
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const {
        deviceInfo: {
            isAppleMobileDevice,
            isDesktopLayoutRecommended,
            isMobileLayoutRecommended,
        },
        navigation: {
            goToStreamerPage,
            gotoStreamerPageSearchResult,
        },
        streamer: {
            streamer,
            state: {
                isLoading: isStreamerLoading,
            },
        },
        streamerArea: {
            stickers: {
                search: {
                    searchQuery,
                    changeSearchQuery,
                },
            },
            supporters: {
                supportersCount,
                isSupportersCountLoading,
            },
        },
    } = props;

    const topic = useMatch(`/${streamer?.channel.name}/:topic`)?.params.topic;

    const {
        setIsStreamerPageFloatingHeaderVisible,
    } = useAppActions();

    const {
        elementRef: floatHeaderWatchdogRef,
        isOnScreen: watchdogIsOnScreen,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const [enteredSearchQuery, setEnteredSearchQuery] = useState(query || '');
    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        switch (action.name) {
            case SearchTextFieldAction.RESET:
                changeSearchQuery('');
                goToStreamerPage(streamer?.channel.link);

                return true;
            case SearchTextFieldAction.ENTER:
                changeSearchQuery(enteredSearchQuery);
                gotoStreamerPageSearchResult(streamer?.channel.link, enteredSearchQuery);
                break;
        }
    };

    const onLostFocus = () => {
        // TODO: Implement platform detection and use it only for Android devices
        if (isMobileLayoutRecommended && !isAppleMobileDevice) {
            logger.trace('Apply Android devices workaround to handle Enter/Tab/Return key on the virtual keyboard', LoggerColor.TEAL);
            changeSearchQuery(enteredSearchQuery);
            gotoStreamerPageSearchResult(streamer?.channel.link, enteredSearchQuery);
        } else {
            logger.trace('Skip Android devices workaround to handle Enter/Tab/Return key on the virtual keyboard', LoggerColor.TEAL);
        }
    };

    useEffect(() => {
        setIsStreamerPageFloatingHeaderVisible(!watchdogIsOnScreen);
    }, [watchdogIsOnScreen]);

    useEffect(() => {
        if (query !== searchQuery) {
            changeSearchQuery(query);
        }

        setEnteredSearchQuery(query);
    }, [query]);

    const containerPadding = isMobileLayoutRecommended
        ? { top: topic ? 10 : 70, left: 8, bottom: 15, right: 8 }
        : { top: 85, left: 15, bottom: 15, right: 15 };

    return (
        <Row padding={containerPadding} className={cn(s.topBlock, { [s.topBlockNoTopic]: !topic })}>
            { (isDesktopLayoutRecommended || (isMobileLayoutRecommended && !topic)) &&
                <>
                    <Avatar
                        source={streamer?.channel.avatarUrl}
                        preventShowDefault={isStreamerLoading || streamer?.channel.avatarUrl !== ''}
                        className={cn(s.topBlockAvatar, { [s.topBlockAvatarMobileLayout]: isMobileLayoutRecommended })}
                        onClick={() => goToStreamerPage(streamer?.channel.link)}
                    />
                    <Column rowGap={7}
                        className={cn(s.topBlockStreamerInfo, { [s.topBlockStreamerInfoMobileLayout]: isMobileLayoutRecommended })}>
                        <Row className={s.topBlockStreamerInfoName} style={{ cursor: 'pointer' }}>
                            <div onClick={() => goToStreamerPage(streamer?.channel.link)}>{streamer?.channel.name}</div>
                        </Row>
                        <Row className={s.topBlockStreamerInfoSupportersCount}>
                            {!isSupportersCountLoading && !!supportersCount
                                ? `${supportersCount} ${declensionUserSupporter(supportersCount, streamer)}`
                                : ''
                            }
                        </Row>
                    </Column>
                </>
            }
            <Column grow>
                <SearchField
                    tag={<IconMagnifierLine/>}
                    defaultValue={searchQuery}
                    placeholder={'Найти стикеры ...'}
                    actions={[
                        {
                            icon: <IconCloseLine/>,
                            name: SearchTextFieldAction.RESET,
                            keyboardKeys: ['Escape'],
                            hidden: !searchQuery,
                        },
                        {
                            icon: <IconArrowRightLine/>,
                            name: SearchTextFieldAction.ENTER,
                            keyboardKeys: ['Enter'],
                            hidden: !enteredSearchQuery,
                        },
                    ]}
                    methodsRef={searchInputMethodsRef}
                    onAction={onSearchTextFieldAction}
                    onChanged={setEnteredSearchQuery}
                    onLeave={onLostFocus}
                />
                <div ref={floatHeaderWatchdogRef}/>
            </Column>
        </Row>
    );
};
