import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams } from '@typings';

interface ISupportersParams extends IHookBaseParams {
    streamerId?: string;
}

export const useSupporters = (params: ISupportersParams) => {
    const {
        active,
        streamerId,
    } = params;

    const {
        supporters: {
            useLazyGetSupportersCountQuery,
        },
    } = useApi();

    const [getSupportersCount, { isLoading }] = useLazyGetSupportersCountQuery();
    const [supportersCount, setSupportersCount] = useState(0);

    useEffect(() => {
        if (streamerId && active) {
            getSupportersCount(streamerId).unwrap().then((r) => setSupportersCount(r ?? 0));
        }
    }, [streamerId, active]);

    return {
        supportersCount,
        isSupportersCountLoading: isLoading,
    };
};
