import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@hooks';

import { useStreamerAlertsSettings } from './StreamerAlertsSettings';
import { useStreamerMemePartySettings } from './StreamerMemePartySettings';
import { useStreamerObsPageSettings } from './StreamerObsPageSettings';
import { useStreamerPageSettings } from './StreamerPageSettings';
import { useStreamerPaymentsSettings } from './StreamerPaymentsSettings';
import { useStreamerReferralSettings } from './StreamerReferralSettings';
import { useStreamerStickersSettings } from './StreamerStickersSettings';

export type StreamerSettingsReturnType = ReturnType<typeof useStreamerSettings>;

export enum StreamerSettingsMode {
    PAGE,
    OBS_PAGE,
    PAYMENTS,
    REFERRALS,
    STICKERS,
    ALERTS,
    MEME_PARTY,
}

export const useStreamerSettings = () => {
    const navigate = useNavigate();
    const { currentUser, refetchCurrentUser, logout, reissueObsToken } = useCurrentUser();
    // const { hasUnreadEvents } = useEventsReadStatus({ currentUser });

    const navigateToStreamerSettingsPage = () => navigate('/channel');
    const navigateToPersonalAreaPage = (query?: string) => navigate('/', { state: { query } });
    const navigateToStickerCreationPage = () => navigate('/sticker-creation');

    return {
        currentUser,
        // hasUnreadEvents,

        page: useStreamerPageSettings(),
        obsPage: useStreamerObsPageSettings(),
        payments: useStreamerPaymentsSettings(),
        referrals: useStreamerReferralSettings(),
        stickers: useStreamerStickersSettings(),
        alerts: useStreamerAlertsSettings(),
        memeparty: useStreamerMemePartySettings(),

        refetchCurrentUser,
        reissueObsToken,
        logout,

        navigateToStreamerSettingsPage,
        navigateToPersonalAreaPage,
        navigateToStickerCreationPage,
    };
};
