import { Button, Column, EColumnAlignment, EComponentColor, EComponentSize, Hint, Icon, Label } from '@hyperclap/ui';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Trans } from 'react-i18next';

import { IconAddImageSolid } from '@assets/images/svg';
import { castToArray } from '@common';
import { TApp } from '@hooks';

import s from './AddMedia.scss';


const FILE_TYPES = ['JPG', 'JPEG', 'PNG', 'GIF', 'MP4'];

interface IAddMediaProps extends TApp {}

export const AddMedia = (props: IAddMediaProps) => {
    const {
        stickersCreation: {
            changeMedia,
        },
        translation: {
            t,
        },
    } = props;

    return (
        <Column grow rowGap={10} alignment={EColumnAlignment.CENTER}>
            <Column
                margin={{ top: 90 }}
                width={560}
                alignment={EColumnAlignment.STRETCH}
                className={s.dropZone}
            >
                <FileUploader handleChange={changeMedia} name="file" types={FILE_TYPES} classes={s.noFocus}>
                    <Column padding={{ top: 40, bottom: 40 }} alignment={EColumnAlignment.CENTER}>
                        <Label
                            margin={{ bottom: 20 }}
                            padding={{ bottom: 10 }}
                            caption={t('sticker-creation.add-media.title')}
                            size={EComponentSize.LARGE}
                        />

                        <Icon icon={<IconAddImageSolid/>} size={EComponentSize.EXTRA_LARGE}/>

                        <Hint margin={{ top: 15, bottom: 25 }} style={{ textAlign: 'center' }} muted>
                            {t('sticker-creation.add-media.hint')}
                        </Hint>

                        <Button color={EComponentColor.ACCENT} width={160} caption={t('common.upload')}/>
                    </Column>
                </FileUploader>
            </Column>

            <Hint grow muted padding={{ top: 15, bottom: 60 }} width={420} textSize={EComponentSize.SMALL} className={s.description}>
                <Label
                    caption={t('sticker-creation.add-media.description.title')}
                    size={EComponentSize.SMALL}
                    muted
                    useBoldFont
                />
                <ul>
                    {
                        castToArray<string>(t('sticker-creation.add-media.description.lines', { returnObjects: true }))
                            .map((element, idx) => (
                                <li key={idx}>
                                    <Trans i18nKey={element} components={[<a key={idx} />]}>
                                        {element}
                                    </Trans>
                                </li>
                            ))
                    }
                </ul>
            </Hint>
        </Column>
    );
};
