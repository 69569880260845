import { useParams } from 'react-router-dom';

import { useStreamer } from '@hooks';

import { useCatalogue } from './Catalogue';
import { useChannel } from './Channel';
import { useLastSent } from './LastSent';
import { useMine } from './Mine';
import { usePopular } from './Popular';
import { useSearch } from './Search';
import { useSupporters } from './Supporters';
import { useThematicSet } from './ThematicSet';
import { useThematicSets } from './ThematicSets';
import { useUserFavorites } from './UserFavorites';


interface IStreamerAreaParams {
    streamerChannelName?: string;
}

export const useStreamerArea = (params: IStreamerAreaParams) => {
    const { streamerChannelName } = params;
    const { streamer } = useStreamer({ streamerChannelName });

    const { thematicSetId } = useParams();

    const catalogue = useCatalogue({ active: !!streamer, streamer });
    const channel = useChannel({ active: !!streamer, streamer });
    const favorites = useUserFavorites({ active: !!streamer, streamer });
    const lastSent = useLastSent({ active: !!streamer, streamer });
    const mine = useMine({ active: !!streamer, streamer });
    const popular = usePopular({ active: !!streamer, streamer });
    const thematicSets = useThematicSets({ active: !!streamer, streamer });
    const search = useSearch({ active: !!streamer, streamer });

    const selectedThematicSet = thematicSets.thematicSets.find(({ id }) => id === thematicSetId);
    const thematicSet = useThematicSet({ active: !!streamer, streamer, thematicSet: selectedThematicSet });

    return {
        stickers: {
            catalogue,
            channel,
            favorites,
            lastSent,
            mine,
            popular,
            thematicSet,
            thematicSets,
            search,
        },
        supporters: useSupporters({ active: !!streamer, streamerId: streamer?.id }),
    };
};
