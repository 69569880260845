import '@hyperclap/ui/dist/hyperclap-ui.css';
import '/src/base/assets/styles/global.scss';
import { Logger, setConfig, ThemeProvider } from '@hyperclap/ui';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from '@app';
import { DOM_ROOT_CONTAINER_ID, ROOT_LOGGER_NAME } from '@common';
import { store } from '@store';

import { memeAlertsRouting } from './routing';
import { Landing } from './views';
import './i18next';

setConfig({
    ...config,
    domModalRootContainerId: 'modal_root',
});

const logger = new Logger({ target: ROOT_LOGGER_NAME, showTimestamp: true });
logger.info(`Application is starting ...`);
logger.trace(config);

config?.developmentMode?.disableStrictMode && logger.warn(`Strict mode is disabled in config.js`);

const container = document.getElementById(DOM_ROOT_CONTAINER_ID);
if (!container) throw new Error(`Root container with id='${DOM_ROOT_CONTAINER_ID}' is missing in the index.html file`);

const root = createRoot(container);

logger.info(memeAlertsRouting);

const t = (
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider>
                <App landing={<Landing/>} routing={memeAlertsRouting}/>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

root.render(config?.developmentMode?.disableStrictMode ? t : <React.StrictMode>{t}</React.StrictMode>);
