import { useLogger } from '@hyperclap/ui';
import { v4 } from 'uuid';

import { useList } from '@hooks';
import { DEFAULT_GEOMETRY, IGeometry, ISticker } from '@typings';

export interface IPlayingSticker {
    uid: string;
    sticker: ISticker;
    geometry: IGeometry;
    hidden?: boolean;
}

interface IPlayingStickerListParams {
    stickerSize?: number;
    availableAreaMask?: number;
    fullscreen?: boolean;
}

export const usePlayingStickersList = (params: IPlayingStickerListParams) => {
    const logger = useLogger({ target: 'usePlayingStickersList' });
    const {
        stickerSize,
        availableAreaMask,
        fullscreen = false,
    } = params;

    const {
        list,
        addToList: add,
        removeFromList: remove,
        clearList,
    } = useList<IPlayingSticker>();

    // eslint-disable-next-line require-jsdoc
    function calcStickerGeometry() {
        if (fullscreen) {
            return {
                x: 0,
                y: 0,
                rotation: 0,
            };
        }

        if (stickerSize && availableAreaMask) {
            logger.trace(`[Sticker size info]: ${JSON.stringify(stickerSize)}%`);

            const zoneIndexes = [];

            for (let i = 0; i <= 8; i++) {
                if ((Math.pow(2, i) & availableAreaMask) > 0) {
                    zoneIndexes.push(i);
                }
            }

            logger.trace(`[Available zone indexes]: ${zoneIndexes}`);

            const randomlySelectedZoneIdx = zoneIndexes[Math.floor(Math.random() * zoneIndexes.length)];

            logger.trace(`[Randomly selected zone]: ${randomlySelectedZoneIdx}`);

            const zoneX = randomlySelectedZoneIdx % 3;
            const zoneY = Math.ceil((1 + randomlySelectedZoneIdx) / 3) - 1;

            logger.trace(`[Zone matrix coords]: Xm: ${zoneX}, Ym: ${zoneY}`);

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            logger.trace(`[Available viewport size]: Width: ${windowWidth}. Height: ${windowHeight}`);

            const viewportDiagonal = Math.sqrt(Math.pow(windowWidth, 2) + Math.pow(windowHeight, 2));

            const windowXPad = [0, 2, 3, 5, 6, 8].includes(randomlySelectedZoneIdx)
                ? [0, 3, 6].includes(randomlySelectedZoneIdx)
                    ? Math.round(viewportDiagonal * stickerSize / 100 / 2)
                    : -Math.round(viewportDiagonal * stickerSize / 100 / 2)
                : 0;
            const windowYPad = [0, 1, 2, 6, 7, 8].includes(randomlySelectedZoneIdx)
                ? [0, 1, 2].includes(randomlySelectedZoneIdx)
                    ? Math.round(viewportDiagonal * stickerSize / 100 / 2)
                    : -Math.round(viewportDiagonal * stickerSize / 100 / 2)
                : 0;

            logger.trace(`[Calculated side paddings]: Xpad: ${windowXPad}, Ypad: ${windowYPad}`);

            const windowXPadTail = [0, 3, 6].includes(randomlySelectedZoneIdx) ? windowXPad : 0;
            const windowYPadTail = [0, 1, 2].includes(randomlySelectedZoneIdx) ? windowYPad : 0;

            const x = ((windowWidth / 3 - Math.abs(windowXPad)) * Math.random()) + windowXPadTail + (windowWidth / 3 * zoneX);
            const y = ((windowHeight / 3 - Math.abs(windowYPad)) * Math.random()) + windowYPadTail + (windowHeight / 3 * zoneY);

            const rotation = Math.random() * 60 - 30;

            logger.trace(`[Calculated sticker coords]: X: ${x}, Y: ${x}, Rotation (deg): ${rotation}`);

            return { x, y, rotation };
        } else {
            return DEFAULT_GEOMETRY;
        }
    }

    const addToList = (playingSticker: ISticker, hidden: boolean = false) => {
        const uid = v4();
        const sticker: ISticker = { ...playingSticker }; // TODO: May bo not needed, check!
        logger.trace(JSON.stringify(sticker));
        const geometry: IGeometry = calcStickerGeometry();
        logger.trace(JSON.stringify(geometry));
        add({ sticker: sticker, geometry, uid, hidden });
    };

    const removeFromList = (uid: string) => {
        remove(uid);
    };

    return {
        list,
        addToList,
        removeFromList,
        clearList,
    };
};
