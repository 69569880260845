import {
    applyMargin,
    applyPadding,
    IBlockComponentProps,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties } from 'react';

import { MemeAlertsLogoDark, MemeAlertsLogoGrayscale, MemeAlertsLogoLight } from './images';
import s from './Logo.scss';


export enum LogoKind {
    LIGHT,
    DARK,
}

interface LogoProps extends IBlockComponentProps {
    className?: string;
    grayscale?: boolean;
    height?: number;
    kind?: LogoKind;
    style?: CSSProperties;
    width?: number;
    onClick?: () => void;
}

export const Logo = (props: LogoProps) => {
    const {
        className,
        grayscale = false,
        height,
        kind = LogoKind.LIGHT,
        margin,
        padding,
        style,
        width,
        onClick,
    } = props;

    const logoClasses = cn({
        [s.logo]: true,
        [s.logoClickable]: !!onClick,
    });

    const logoStyles: CSSProperties = {
        ...style,
        height,
        width,
    };

    applyPadding(logoStyles, padding);
    applyMargin(logoStyles, margin);

    return (
        <div className={cn(logoClasses, className)} style={logoStyles} onClick={onClick}>
            {grayscale
                ? <MemeAlertsLogoGrayscale/>
                : kind === LogoKind.LIGHT
                    ? <MemeAlertsLogoLight />
                    : <MemeAlertsLogoDark />
            }
        </div>
    );
};
