import { useEffect, useRef, useState } from 'react';

import { sortObjects } from '@common';
import { useApi } from '@hooks/webapi';
import { IHookBaseParams, ISticker, IThematicSet, IUser } from '@typings';

interface ThematicSetsParams extends IHookBaseParams {
    streamer?: IUser;
}

interface IThematicSetsStickers {
    [setId: string]: Array<ISticker>;
}

export const useThematicSets = (params: ThematicSetsParams) => {
    const { active, streamer } = params;

    const {
        thematicSet: {
            useLoadAvailableThematicSetsQuery,
        },
        stickers: {
            useLazyLoadPageStreamerAreaThematicSetQuery,
        },
    } = useApi();

    const { data: loadedAvailableThematicSets } = useLoadAvailableThematicSetsQuery(void(0), { skip: !active });
    const [loadThematicSet] = useLazyLoadPageStreamerAreaThematicSetQuery();

    const [thematicSets, setThematicSets] = useState<Array<IThematicSet>>([]);
    const [thematicSetsStickers, setThematicSetsStickers] = useState<IThematicSetsStickers>({});
    const thematicSetsStickersRef = useRef<IThematicSetsStickers>({});

    useEffect(() => {
        setThematicSets(sortObjects([...loadedAvailableThematicSets ?? []], 'beginDate'));
    }, [loadedAvailableThematicSets, active]);

    useEffect(() => {
        if (streamer) {
            thematicSets.forEach(async (ts: IThematicSet) => {
                const result = await loadThematicSet({
                    limit: 20,
                    streamerId: streamer.id,
                    skip: 0,
                    thematicSetId: ts.id,
                }).unwrap();

                if (result) {
                    const stickers = { ...thematicSetsStickersRef.current };
                    stickers[ts.id] = result;
                    thematicSetsStickersRef.current = stickers;
                    setThematicSetsStickers(stickers);
                }
            });
        }
    }, [thematicSets]);

    return {
        thematicSets,
        thematicSetsStickers,
    };
};
