import { IAvatarAsset, IAvatarAssetColorsMapVariableItem, IAvatarAssetFaceEmotion } from '@typings';

export const faceEmotionToAsset: (
    faceEmotion: IAvatarAssetFaceEmotion,
    eyeColor: IAvatarAssetColorsMapVariableItem
) => IAvatarAsset = (
    faceEmotion,
    eyeColor,
) => ({
    id: faceEmotion?.id,
    title: '',
    categoryId: '',
    layers: [
        {
            id: faceEmotion?.id,
            positionId: null as unknown as string,
            image: faceEmotion?.image,
            isFace: true,
        },
    ],
    hiddenLayerPositions: [],
    colorsMap: {
        variable: {
            colors: [eyeColor],
            images: [],
        },
        permanent: {
            colors: [],
            images: [],
        },
    },
    sortIndex: 0,
});
