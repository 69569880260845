import { useEffect, useState } from 'react';

import { useApi, useCurrentUser, useWebSocket } from '@hooks';
import { IHookBaseParams, TWSMessage, WSMessageType } from '@typings';

const BALANCE_CHANGE_RELATED_WS_MESSAGES = [WSMessageType.DECREMENT_BALANCE, WSMessageType.INCREMENT_BALANCE];

interface BalanceParams extends IHookBaseParams {
    streamerName?: string
}

export const useBalance = (props: BalanceParams) => {
    const {
        streamerName,
    } = props;

    const [balance, setBalance] = useState(0);
    const [welcomeBonusEarned, setWelcomeBonusEarned] = useState(true);
    const [newbieActionUsed, setNewbieActionUsed] = useState(true);
    const [balanceLoaded, setBalanceLoaded] = useState(false);

    const {
        users: {
            useGetBalanceByStreamerNameQuery,
            useEarnWelcomeBonusMutation,
        },
        payments: {
            useLazyGetPaymentUrlQuery,
        },
    } = useApi();

    const { currentUser } = useCurrentUser();
    const [earnWelcomeBonus] = useEarnWelcomeBonusMutation();

    const {
        data: fetchedBalance,
        isSuccess: isLoaded,
        refetch: reloadBalance,
    } = useGetBalanceByStreamerNameQuery(streamerName || '');

    const [buyCurrency] = useLazyGetPaymentUrlQuery();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket();

    const onMessageHandler = (message: TWSMessage) => {
        const { type } = message;

        if (BALANCE_CHANGE_RELATED_WS_MESSAGES.includes(type)) {
            reloadBalance();
        }
    };

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser]);

    useEffect(() => {
        setBalance(fetchedBalance?.balance || 0);
        setWelcomeBonusEarned(fetchedBalance?.welcomeBonusEarned ?? true);
        setNewbieActionUsed(fetchedBalance?.newbieActionUsed ?? true);
        setBalanceLoaded(isLoaded);
    }, [fetchedBalance]);

    // useEffect(() => {
    //
    // }, [isLoaded]);

    return {
        balance,
        balanceLoaded,
        welcomeBonusEarned,
        newbieActionUsed,
        buyCurrency,
        earnWelcomeBonus,
        reloadBalance,
    };
};
