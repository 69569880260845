import {
    Button,
    Column,
    Divider,
    Hint,
    Label,
    NumericField,
    Row,
    Switch,
    TextField,
    EComponentColor,
    EComponentSize,
    ERowAlignment, useLogger,
} from '@hyperclap/ui';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React, { useEffect, useRef, useState } from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { IconCopyLine, IconExclamationOctagonLine, IconTrashLine } from '@assets/images/svg';
import {
    AUTH_TOKEN_NAME,
    copyToClipboard,
    declensionRubles,
    declensionUserCurrency,
    DEFAULT_NEWBIE_ACTION,
    DEFAULT_WELCOME_BONUS,
} from '@common';
import { Avatar } from '@components';
import { NotificationType } from '@contexts';
import { useAppContext, useNotifications } from '@hooks';


import s from './PaymentsSettings.scss';

const MIN_BONUS_SUM = 1;
const MAX_BONUS_SUM = 100;
const EXCHANGE_RATE = 10;

let authWindow: Window | null;

export const PaymentsSettings = () => {
    const logger = useLogger({ target: PaymentsSettings.name, showTimestamp: true });
    const app = useAppContext();

    const {
        currentUser: {
            currentUser,
            refetchCurrentUser,
        },
        settings: {
            streamer: {
                page: {
                    updateChannel,
                },
                payments: {
                    isCurrencyImageCleared,
                    channelCurrencyImageData,
                    setChannelCurrencyName,
                    setChannelSupporterName,
                    onChangeChannelCurrencyImage,
                    updateChannelCurrencyImage,
                    resetCurrencyImage,
                    onResetCurrencyImage,
                },
            },
            system,
        },
    } = app;

    const daConnected = !!currentUser?.channel.donationAlertsUsername;
    const welcomeBonusDefaultValue = currentUser?.channel?.welcomeBonus
        ? currentUser?.channel?.welcomeBonus
        : currentUser?.channel?.catalogueStickerPrice
            ? currentUser?.channel?.catalogueStickerPrice + 1
            : DEFAULT_WELCOME_BONUS;
    const newbieActionDefaultValue = currentUser?.channel?.newbieAction
        ? currentUser?.channel?.newbieAction
        : currentUser?.channel?.catalogueStickerPrice
            ? currentUser?.channel?.catalogueStickerPrice + 1
            : DEFAULT_NEWBIE_ACTION;

    const { notify } = useNotifications();

    const [welcomeBonusEnabled, setWelcomeBonusEnabled] = useState<boolean>(currentUser?.channel?.welcomeBonusEnabled ?? true);
    const [welcomeBonus, setWelcomeBonus] = useState(0);
    const [newbieActionEnabled, setNewbieActionEnabled] = useState<boolean>(currentUser?.channel?.newbieActionEnabled ?? true);
    const [newbieAction, setNewbieAction] = useState(0);
    const [isSavePricesEnabled, setIsSavePricesEnabled] = useState(false);

    const currencyRef = useRef<HTMLInputElement>(null);

    const onSavePriceDataClick = () => {
        updateChannel({
            welcomeBonusEnabled,
            welcomeBonus: Number(welcomeBonus),
            newbieActionEnabled,
            newbieAction: Number(newbieAction),
        });

        if (channelCurrencyImageData) {
            updateChannelCurrencyImage();
        }

        if (isCurrencyImageCleared) {
            resetCurrencyImage();
        }
    };

    const onResetCurrencyImageClick = () => {
        if (currencyRef.current) {
            currencyRef.current.value = '';
        }

        onResetCurrencyImage();
    };

    const morphCurrency = async (word: string) => {
        if (word && word !== currentUser?.channel.currencyName) {
            setChannelCurrencyName(word.toString());
        }
    };

    const morphSupporter = async (word: string) => {
        if (word && word !== currentUser?.channel.supporterName) {
            setChannelSupporterName(word.toString());
        }
    };

    const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            onChangeChannelCurrencyImage(files[0]);
        }
    };

    const getDonationAlertsAuthUrl = () => {
        return `${config.hosts.apiHost}/auth/da?token=${localStorage.getItem(AUTH_TOKEN_NAME)}&return_url=${encodeURIComponent(
            `${location.protocol}//${location.host}/auth/donationalerts`,
        )}`;
    };

    const onDonationAlertsAuthClick = () => {
        authWindow = window.open(getDonationAlertsAuthUrl(), '_blank');
    };

    const onDonationAlertsLinkFieldAction = () => {
        void copyToClipboard('https://donationalerts.com/r/' + currentUser?.channel.donationAlertsUsername);
        notify('Ссылка скопирована в буфер обмена', NotificationType.SUCCESS);
    };

    const onMessage = (event: MessageEvent) => {
        try {
            const message = event.data as { status: string };

            if (message.status) {
                authWindow?.close();

                if (message.status === 'success') {
                    refetchCurrentUser();
                    notify('Пользователь Donation Alerts успешно привязан', NotificationType.SUCCESS);
                }

                if (message.status === 'invalid_name') {
                    notify('Завершите создание профиля в Donation Alerts и повторите попытку', NotificationType.ERROR, 10000);
                }

                if (message.status === 'error') {
                    notify('Произошла ошибка привязки аккаунта Donation Alerts', NotificationType.ERROR, 10000);
                }
            }
        } catch (error) {
            logger.error(error);
        }
    };

    useEffect(
        () => {
            window.addEventListener('message', onMessage);

            return () => {
                window.removeEventListener('message', onMessage);
            };
        },
        [],
    );

    useEffect(() => {
        if (currentUser) {
            setWelcomeBonusEnabled(currentUser.channel?.welcomeBonusEnabled ?? false);
            setNewbieActionEnabled(currentUser.channel?.welcomeBonusEnabled ?? false);

            const welcomeBonus = currentUser.channel.welcomeBonus
                ? currentUser.channel.welcomeBonus
                : currentUser.channel.catalogueStickerPrice
                    ? currentUser.channel.catalogueStickerPrice + 1
                    : DEFAULT_WELCOME_BONUS;
            const newbieAction = currentUser.channel.newbieAction
                ? currentUser.channel.newbieAction
                : currentUser.channel.catalogueStickerPrice
                    ? currentUser.channel.catalogueStickerPrice + 1
                    : DEFAULT_NEWBIE_ACTION;

            setWelcomeBonus(welcomeBonus);
            setNewbieAction(newbieAction);
        }
    }, [currentUser]);

    useEffect(() => {
        setIsSavePricesEnabled(
            !!welcomeBonus && welcomeBonus >= MIN_BONUS_SUM && welcomeBonus <= MAX_BONUS_SUM &&
            !!newbieAction && newbieAction >= MIN_BONUS_SUM && newbieAction <= MAX_BONUS_SUM,
        );
    }, [newbieAction, welcomeBonus]);

    // let currencyNameDeclensions = currentUser?.channel.currencyNameDeclensions;
    const accusative = currentUser?.channel.currencyName;

    return (
        currentUser
            ? <Row columnGap={20} margin={{ top: 15, bottom: 50 }} alignment={ERowAlignment.TOP}>
                <Column width={510} rowGap={10}>
                    <Label padding={{ bottom: 10 }} caption={'Выплаты'} size={EComponentSize.LARGE}/>

                    { currentUser?.channel?.donationAlertsUsername &&
                    <Column>
                        <Row columnGap={15} alignment={ERowAlignment.BOTTOM}>
                            <Column grow>
                                <TextField
                                    caption={'Подключить аккаунт DonationAlerts'}
                                    defaultValue={'https://donationalerts.com/r/' + currentUser.channel.donationAlertsUsername}
                                    readonly
                                    actions={[
                                        { name: 'copy', icon: <IconCopyLine/> },
                                    ]}
                                    onAction={onDonationAlertsLinkFieldAction}
                                />
                            </Column>
                            <Button
                                caption='Изменить'
                                color={EComponentColor.ACCENT}
                                width={120}
                                onClick={onDonationAlertsAuthClick}
                            />
                        </Row>
                        <Hint margin={{ top: 5 }} muted textSize={EComponentSize.SMALL}>
                            {'Если подключить не получается, то попробуйте\nпереключить браузер в режим Инкогнито и\nповторить попытку.'}
                        </Hint>
                    </Column>
                    }
                    { currentUser && !currentUser?.channel.donationAlertsUsername &&
                    <Column>
                        <Label
                            caption={'Ссылка на страницу DonationAlerts'}
                            color={EComponentColor.DANGER}
                            icon={<IconExclamationOctagonLine/>}
                            size={EComponentSize.MEDIUM}
                        />
                        <Hint margin={{ top: 5 }} muted textSize={EComponentSize.SMALL}>
                            {'Если подключить не получается, то попробуйте\nпереключить браузер в режим Инкогнито и\nповторить попытку.'}
                        </Hint>
                        <Button
                            caption='Подключить'
                            color={EComponentColor.ACCENT}
                            margin={{ top: 10 }}
                            width={160}
                            onClick={onDonationAlertsAuthClick}
                        />
                    </Column>
                    }

                    <Divider margin={{ top: 20, bottom: 20 }} width={510}/>

                    <Label padding={{ bottom: 10, top: 0 }} caption={'Виртуальная валюта'} size={EComponentSize.LARGE}/>

                    <Row margin={{ bottom: 10 }} >
                        <Column>
                            <TextField
                                caption={'Название вашей валюты'}
                                defaultValue={currentUser?.channel?.currencyName}
                                maxLength={15}
                                debounceTime={1500}
                                invalidSymbols={/[^а-яА-Я]/g}
                                onChanged={morphCurrency}
                            />
                            <Hint margin={{ top: 5 }} muted textSize={EComponentSize.SMALL}>
                                {'Именительный падеж, единственное число'}
                            </Hint>
                        </Column>
                    </Row>

                    <Row margin={{ bottom: 10 }}>
                        <Column>
                            <Row>
                                <Label padding={{ top: 0 }} caption={'Иконка вашей валюты'} size={EComponentSize.MEDIUM} />
                            </Row>
                            <Row columnGap={15}>
                                <Column>
                                    <Avatar
                                        className={s.roundedAvatar}
                                        fallbackImage={ImageDefaultCurrency}
                                        source={isCurrencyImageCleared
                                            ? ImageDefaultCurrency
                                            : channelCurrencyImageData || (currentUser?.channel?.currencyImageUrl || ImageDefaultCurrency)}
                                        size={EComponentSize.SMALL}
                                        inputRef={currencyRef}
                                        rounded
                                    />
                                </Column>
                                <Column grow>
                                    <Button caption={'Изменить'} className={s.loadCurrencyImageButton}>
                                        <input type={'file'} className={s.loadCurrencyImageButtonInput} onChange={onImageChange}/>
                                    </Button>
                                </Column>
                                <Column>
                                    <Button
                                        caption={'Сбросить'}
                                        icon={<IconTrashLine/>}
                                        linkStyle
                                        width={120}
                                        onClick={onResetCurrencyImageClick}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <TextField
                                caption={'Название вашего саппортера'}
                                defaultValue={currentUser?.channel?.supporterName}
                                debounceTime={1500}
                                invalidSymbols={/[^а-яА-Я]/g}
                                width={270}
                                onChanged={morphSupporter}
                            />
                            <Hint margin={{ top: 5 }} muted textSize={EComponentSize.SMALL}>
                                {'Зритель, который отправляет стикеры и донаты.\nИменительный падеж, единственное число'}
                            </Hint>
                        </Column>
                    </Row>

                    <Row>
                        <Button
                            disabled={(Number(welcomeBonus) < MIN_BONUS_SUM) || (Number(newbieAction) < MIN_BONUS_SUM)}
                            caption='Сохранить'
                            color={EComponentColor.ACCENT}
                            width={160}
                            onClick={onSavePriceDataClick}
                        />
                    </Row>

                    <Divider margin={{ top: 20, bottom: 20 }} width={510}/>

                    <Label padding={{ bottom: 10, top: 0 }} caption={'Бонусы'} size={EComponentSize.LARGE}/>

                    { system.asBool('bonuses.enableWelcomeBonus') &&
                    <Row margin={{ bottom: 10 }}>
                        <Column>
                            <Row margin={{ bottom: 10 }}>
                                <Label padding={{ top: 0, bottom: 0 }} width={220} caption='Welcome бонус' size={EComponentSize.MEDIUM} />
                                <Switch
                                    checked={currentUser?.channel?.welcomeBonusEnabled ?? false}
                                    color={EComponentColor.ACCENT}
                                    onChange={setWelcomeBonusEnabled}
                                />
                            </Row>

                            { welcomeBonusEnabled &&
                                <Row>
                                    <Column>
                                        <NumericField
                                            min={MIN_BONUS_SUM}
                                            max={MAX_BONUS_SUM}
                                            defaultValue={welcomeBonusDefaultValue}
                                            actions={[
                                                { text: declensionUserCurrency(Number(welcomeBonus), currentUser), noInteraction: true },
                                            ]}
                                            width={270}
                                            errorMessage={
                                                !welcomeBonus || welcomeBonus < MIN_BONUS_SUM
                                                    ? `Минимальный размер Welcome-бонуса - ${
                                                        MIN_BONUS_SUM
                                                    } ${
                                                        declensionUserCurrency(MIN_BONUS_SUM, currentUser)
                                                    }`
                                                    : welcomeBonus > MAX_BONUS_SUM
                                                        ? `Максимальный размер Welcome-бонуса - ${
                                                            MAX_BONUS_SUM
                                                        } ${
                                                            declensionUserCurrency(MAX_BONUS_SUM, currentUser)
                                                        }`
                                                        : ''
                                            }
                                            hasError={!welcomeBonus || welcomeBonus < MIN_BONUS_SUM || welcomeBonus > MAX_BONUS_SUM}
                                            onChanged={(val) => setWelcomeBonus(val)}
                                        />
                                        <Hint muted margin={{ top: 5 }} textSize={EComponentSize.SMALL}>
                                            {`Зритель получит ${
                                                welcomeBonus ?? 0
                                            } ${
                                                declensionUserCurrency(Number(welcomeBonus), currentUser)
                                            } при первом посещении\nвашей страницы.`}
                                        </Hint>
                                    </Column>
                                </Row>
                            }
                        </Column>
                    </Row>
                    }

                    { system.asBool('bonuses.enableNewbieAction') &&
                    <Row margin={{ bottom: 10 }}>
                        <Column>
                            <Row margin={{ bottom: 10 }}>
                                <Label
                                    padding={{ top: 0, bottom: 0 }}
                                    width={220}
                                    caption='Бонус на первый платеж'
                                    size={EComponentSize.MEDIUM}
                                />
                                <Switch
                                    checked={currentUser?.channel?.newbieActionEnabled ?? false}
                                    color={EComponentColor.ACCENT}
                                    onChange={setNewbieActionEnabled}
                                />
                            </Row>

                            { newbieActionEnabled &&
                                <Row>
                                    <Column>
                                        <NumericField
                                            min={MIN_BONUS_SUM}
                                            max={MAX_BONUS_SUM}
                                            defaultValue={newbieActionDefaultValue}
                                            actions={[
                                                { text: declensionUserCurrency(Number(newbieAction), currentUser), noInteraction: true },
                                            ]}
                                            width={270}
                                            errorMessage={
                                                !newbieAction || newbieAction < MIN_BONUS_SUM
                                                    ? `Минимальное количество - ${
                                                        MIN_BONUS_SUM
                                                    } ${
                                                        declensionUserCurrency(MIN_BONUS_SUM, currentUser)
                                                    }`
                                                    : newbieAction > MAX_BONUS_SUM
                                                        ? `Максимальное количество - ${
                                                            MAX_BONUS_SUM
                                                        } ${
                                                            declensionUserCurrency(MAX_BONUS_SUM, currentUser)
                                                        }`
                                                        : ''
                                            }
                                            hasError={!newbieAction || newbieAction < MIN_BONUS_SUM || newbieAction > MAX_BONUS_SUM}
                                            onChanged={(val) => setNewbieAction(val)}
                                        />
                                        <Hint margin={{ top: 5 }} muted textSize={EComponentSize.SMALL}>
                                            {`При первом платеже зрителю доступна акция ${
                                                newbieAction ?? 0
                                            } ${
                                                declensionUserCurrency(Number(newbieAction), currentUser)}\nза 1 рубль.`
                                            }
                                        </Hint>
                                    </Column>
                                </Row>
                            }
                        </Column>
                    </Row>
                    }

                    <Row>
                        <Button
                            disabled={!isSavePricesEnabled}
                            caption='Сохранить'
                            color={EComponentColor.ACCENT}
                            width={160}
                            onClick={onSavePriceDataClick}
                        />
                    </Row>
                </Column>
                <Column grow>
                    <Row>
                        <Hint
                            grow
                            color={daConnected ? EComponentColor.SUCCESS : EComponentColor.DANGER}
                            textSize={EComponentSize.SMALL}
                        >
                            {currentUser && !currentUser?.channel?.donationAlertsUsername
                                ? 'ВНИМАНИЕ! Добавьте ссылку на DonationAlerts, чтобы подключить\nвыплаты.'
                                : <Column>
                                    <Label
                                        color={EComponentColor.SUCCESS}
                                        caption={'Монетизация подключена.'}
                                        size={EComponentSize.SMALL}
                                        useBoldFont
                                    />
                                    <Hint muted>{'Вы будете видеть покупки виртуальной валюты как донаты\n\
в личном кабинете DonationAlerts. Общая комиссия\n\
составляет 25%. Вы получаете деньги в момент покупки\n\
виртуальной валюты.'}</Hint>
                                </Column>
                            }
                        </Hint>
                    </Row>
                    <Row>
                        <Hint grow
                            muted
                            textSize={EComponentSize.SMALL}
                        >
                            <Column>
                                <Label
                                    color={EComponentColor.SUCCESS}
                                    caption={`Один ${accusative} стоит ${EXCHANGE_RATE} ${declensionRubles(EXCHANGE_RATE)}`}
                                    useBoldFont
                                />
                                <Hint muted>
                                    {'Зрители покупают вашу виртуальную валюту, которую тратят\n\
затем на отправку донатов. Вы получаете деньги в момент \n\
покупки виртуальной валюты.'}
                                </Hint>
                            </Column>
                        </Hint>
                    </Row>
                </Column>
            </Row>
            : null
    );
};
