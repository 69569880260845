import { ETheme, ThemeContext } from '@hyperclap/ui';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApp } from '@hooks';

import { Desktop, Mobile } from './layouts';


export const Landing = () => {
    const { changeTheme } = useContext(ThemeContext);

    const app = useApp();
    const {
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        telegram: {
            registerLoginRequest,
        },
    } = app;
    const [searchParams, setSearchParams] = useSearchParams();

    const isTelegramLogin = searchParams.has('id') && searchParams.has('auth_date') && searchParams.has('hash');

    useLayoutEffect(() => {
        changeTheme(ETheme.LIGHT, true);
    }, []);

    useEffect(() => {
        if (isTelegramLogin) {
            void registerLoginRequest(searchParams.toString());
            setSearchParams(new URLSearchParams());
        }
    }, []);

    return (
        isMobileLayoutRecommended
            ? <Mobile {...app}/>
            : <Desktop {...app}/>
    );
};
