import { Button, Column, Paginator, Row, useClickOutside } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconDollarLine } from '@assets/images/svg';
import { DATE_FORMAT_LONG, declensionUserCurrency } from '@common';
import { NotificationType } from '@contexts';
import { TApp, useNotifications } from '@hooks';
import { useSupporter } from '@hooks/app/personal-area/Supporter';

import s from './Desktop.scss';
import { BonusPopup, EventsTable } from '../../components';


interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const { notify } = useNotifications();
    const { supporterId } = useParams();
    const {
        currentUser: {
            currentUser,
        },
        translation: {
            i18n,
            t,
        },
        navigation: {
            goToSupporters,
        },
    } = props;

    const {
        bonusSize,
        events,
        pagesCount,
        supporter,
        supporterNotFound,
        changePage,
        sendBonus,
        setBonusSize,
    } = useSupporter({ supporterId: supporterId ?? '' });

    const dataReady = currentUser && events;

    const [joinDate, setJoinDate] = useState('');
    const [balance, setBalance] = useState(0);
    const [isBonusPopupShown, setIsBonusPopupShown] = useState(false);

    const bonusPopupRef = useRef<HTMLDivElement>(null);

    useClickOutside({ controlledElementRef: bonusPopupRef, handler: () => setIsBonusPopupShown(false) });

    useEffect(() => {
        if (supporter) {
            setJoinDate(new Date(supporter.joined).toLocaleString(i18n.language, DATE_FORMAT_LONG));
            setBalance(supporter.balance);
        }
    }, [supporter]);

    useEffect(() => {
        if (supporterNotFound) goToSupporters();
    }, [supporterNotFound]);

    const onBonusButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (isBonusPopupShown) {
            setIsBonusPopupShown(false);
        } else if (currentUser) {
            setIsBonusPopupShown(true);
        }
    };

    const onSendBonusClick = async () => {
        sendBonus(supporter!.supporterId, currentUser!.id);
        setBalance((prev) => prev + bonusSize);
        setIsBonusPopupShown(false);
        notify('Награда успешно отправлена!', NotificationType.SUCCESS);
    };

    return (
        <Column grow margin={{ top: 25 }} rowGap={24}>
            <Row className={s.header}>
                <Column rowGap={10}>
                    <Row>
                        {supporter?.supporterName}
                    </Row>
                    <Row className={s.counterDescription}>
                        {t('supporters.joined')}: {joinDate}
                    </Row>
                </Column>
                <Column grow/>
            </Row>

            <Row>
                <Column rowGap={10} margin={{ right: 88 }}>
                    <Row className={s.counterValue} columnGap={5}>
                        <Column>
                            {Math.round(balance ?? 0)}
                        </Column>
                        <Column>
                            {declensionUserCurrency(Math.round(balance ?? 0), currentUser)}
                        </Column>
                    </Row>
                    <Row className={s.counterDescription}>
                        {t('supporters.balance')}
                    </Row>
                </Column>
                <Column rowGap={5}>
                    <Row className={s.counterValue} columnGap={5}>
                        <Column>
                            {Math.round(supporter?.spent ?? 0)}
                        </Column>
                        <Column>
                            {declensionUserCurrency(Math.round(supporter?.spent ?? 0), currentUser)}
                        </Column>
                    </Row>
                    <Row className={s.counterDescription}>
                        {t('supporters.total-spent')}
                    </Row>
                </Column>
                <Column grow/>
                <Column margin={{ right: 15 }} className={s.bonusPopupContainer}>
                    <Button
                        caption={t('supporters.give-bonus')}
                        className={s.button}
                        icon={<IconDollarLine/>}
                        onClick={onBonusButtonClick}
                    />
                    { !!currentUser &&
                    <BonusPopup
                        currentUser={currentUser}
                        visible={isBonusPopupShown && !!supporter}
                        ref={bonusPopupRef}
                        onBonusSizeChange={setBonusSize}
                        onCloseClick={() => setIsBonusPopupShown((prev) => !prev)}
                        onSendBonusClick={onSendBonusClick}
                    />
                    }
                </Column>
                {pagesCount > 1 &&
                    <Column>
                        <Paginator
                            page={1}
                            pagesCount={pagesCount}
                            maxDisplayedPages={7}
                            showNavigation
                            onChangePage={changePage}
                        />
                    </Column>
                }
            </Row>
            <Row>
                {dataReady && <EventsTable data={events} currentUser={currentUser}/>}
            </Row>
        </Column>
    );
};
