import {
    Button,
    Column,
    Row,
    useDeviceInfo,
    useModals,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { IconCloseLine } from '@assets/images/svg';
import { declensionUserCurrency } from '@common';
import { Avatar } from '@components';
import { IUser } from '@typings';

import s from './WelcomeBonusPopup.scss';


interface WelcomeBonusPopupProps {
    streamer: IUser;
}

export const WelcomeBonusPopup = (props: WelcomeBonusPopupProps) => {
    const { closeModal } = useModals();
    const { isMobileLayoutRecommended } = useDeviceInfo();
    const { streamer } = props;
    const {
        channel: {
            currencyImageUrl,
            welcomeBonus,
        },
    } = streamer;

    const currencyDecName = declensionUserCurrency(Number(welcomeBonus), streamer);

    return (
        <Column className={s.welcomeBonusPopup}>
            <Column className={s.welcomeBonusPopupContent}>
                <Row className={s.currencyImage}>
                    <Avatar rounded className={s.currencyImageBottom} source={currencyImageUrl || ImageDefaultCurrency}
                        size={EComponentSize.LARGE}/>
                    <Avatar rounded className={s.currencyImageBottom} source={currencyImageUrl || ImageDefaultCurrency}
                        size={EComponentSize.LARGE}/>
                    <Avatar rounded className={s.currencyImageTop} source={currencyImageUrl || ImageDefaultCurrency}
                        size={EComponentSize.LARGE}/>
                </Row>
                <Row className={cn(s.popupRow, s.popupRowBonusValue)}>
                    {welcomeBonus}
                </Row>
                <Row className={cn(s.popupRow, s.popupRowCurrencyName)}>
                    {currencyDecName}
                </Row>
                <Row className={cn(s.popupRow, s.popupRowBonusText)}>
                    {`Ура! Вы получили бонус ${welcomeBonus} ${currencyDecName}. `}
                    {`Вы можете потратить их на отправку стикеров на канале ${streamer.channel.name}.`}
                </Row>
                <Row className={s.popupRow}>
                    <Button
                        color={EComponentColor.ACCENT}
                        caption={'Спасибо!'}
                        onClick={closeModal}
                    />
                </Row>
            </Column>

            <div
                className={cn(
                    s.closeButton,
                    {
                        [s.closeButtonMobileLayout]: isMobileLayoutRecommended,
                    },
                )}
                onClick={closeModal}
            >
                <IconCloseLine/>
            </div>
        </Column>
    );
};
