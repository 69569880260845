import React from 'react';

import { TApp } from '@hooks';
import { StickerCreationStage } from '@typings';
import { StickerPlayer } from '@views';
import { AddAudio, AddDetails, AddMedia, BusyBox, Finish } from '@views/pages/CreateSticker/components';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        stickersCreation: {
            busyMessage,
            isBusy,
            playingStickersList: {
                list,
                removeFromList,
            },
            stage,
        },
    } = props;

    const playingStickers = list.map((sticker) => (
        <StickerPlayer
            key={sticker.uid}
            sticker={sticker.sticker}
            geometry={sticker.geometry}
            onPlayEnd={() => removeFromList(sticker.uid)}
        />
    ));

    return (
        <>
            { isBusy
                ? <BusyBox caption={busyMessage}/>
                : <>
                    { stage === StickerCreationStage.ADD_MEDIA && <AddMedia {...props} />}
                    { stage === StickerCreationStage.ADD_AUDIO && <AddAudio {...props} />}
                    { stage === StickerCreationStage.ADD_DETAILS && <AddDetails {...props} />}
                    { stage === StickerCreationStage.FINISH && <Finish {...props} />}
                </>
            }
            { playingStickers }
        </>
    );
};
