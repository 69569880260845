import { useRef, useState } from 'react';

export const useList = <T extends { uid: string }>() => {
    const [list, setList] = useState<Array<T>>([]);
    const listRef = useRef(list);

    const addToList = (element: T) => {
        const newList = listRef.current.concat(element);
        listRef.current = newList;
        setList(newList);
    };

    const removeFromList = (uid: string) => {
        const newList = listRef.current.filter((e) => e.uid !== uid);
        listRef.current = newList;
        setList(newList);
    };

    const clearList = () => {
        listRef.current = [];
        setList([]);
    };

    return {
        list,
        addToList,
        removeFromList,
        clearList,
    };
};
