import {
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentSize,
    ERowAlignment,
    Icon,
    Loader,
    Row,
} from '@hyperclap/ui';
import cn from 'classnames';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';


import {
    IconCheckCircleLine,
    IconDenyCircleLine,
    IconDollarLine,
    IconPoopLine,
    IconPoopSolid,
    IconReplayLine,
    IconStarSolid,
} from '@assets/images/svg';
import { declensionUserCurrency, ONE_MINUTE, ONE_SECOND } from '@common';
import { EventKind, IUser, ModerationState, TMemeAlertsEvent } from '@typings';

import s from './EventItem.scss';

interface IEventItemProps {
    className?: string;
    currentUser?: IUser;
    event: TMemeAlertsEvent;
    onReplayClick(event: TMemeAlertsEvent): void;
    onToggleDisableStateClick(event: TMemeAlertsEvent): void;
}

const NO_NAME = t('events.without-name');
const ANONYMOUS = t('common.anonymous');

const getDateTimeString = (event: TMemeAlertsEvent) => {
    const rightNow = Date.now();

    const diff = rightNow - event.timestamp;

    return diff < ONE_MINUTE
        ? t('common.now')
        : new Date(event.timestamp).toLocaleString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).replace(',', '');
};

let timer: NodeJS.Timeout;

export const EventItem = (props: IEventItemProps) => {
    const {
        className,
        currentUser,
        event,
        onReplayClick,
        onToggleDisableStateClick,
    } = props;

    const eventRef = useRef(event);

    const [isReplayInProcess, setIsReplayInProcess] = useState(false);
    const [isBanInProcess, setIsBanInProcess] = useState(false);
    const [eventDateTime, setEventDateTime] = useState<string>(getDateTimeString(event));
    const [isHighlighted, setIsHighlighted] = useState(false);

    const onReplayButtonClick = () => {
        setIsReplayInProcess(true);

        onReplayClick(event);

        setTimeout(() => {
            setIsReplayInProcess(false);
        }, 1000);
    };

    const onPoopButtonClick = () => {
        setIsBanInProcess(true);

        setTimeout(() => {
            onToggleDisableStateClick(event);
            setIsBanInProcess(false);
        }, 1000);
    };

    const getIcon = () => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
            case EventKind.MEME_CANNON_STICKER_SENT:
                return <IconStarSolid />;
            case EventKind.BUY_CURRENCY:
                return <IconDollarLine />;
            case EventKind.MODERATION_STATUS:
                return event.status === ModerationState.APPROVED ? <IconCheckCircleLine /> : <IconDenyCircleLine />;
            case EventKind.WELCOME_BONUS_EARNED:
            case EventKind.BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return <IconDollarLine />;
            default:
                return <IconStarSolid />;
        }
    };

    const getIconColor = () => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return event.fullscreen ? 'Blue' : 'Orange';
            case EventKind.MEME_CANNON_STICKER_SENT:
                return 'Fuchsia';
            case EventKind.BUY_CURRENCY:
                return 'Green';
            case EventKind.MODERATION_STATUS:
                return event.status === ModerationState.APPROVED ? 'Green' : 'Red';
            case EventKind.WELCOME_BONUS_EARNED:
            case EventKind.BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return 'Blue';
            default:
                return 'Default';
        }
    };

    const getUserAndAction = () => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return <>
                    <span className={s.messageUserAlias}>
                        <Link to={`/supporters/${event.userId}`} className={s.messageUserAliasLink}>
                            {event.userAlias || ANONYMOUS}
                        </Link>
                    </span>
                    <span className={s.messageText}>
                        {event.fullscreen
                            ? t('events.sent-fullscreen')
                            : t('events.sent')
                        }
                    </span>
                </>;
            case EventKind.MEME_CANNON_STICKER_SENT:
                return <>
                    <span className={s.messageUserAlias}>
                        <Link to={`/supporters/${event.userId}`} className={s.messageUserAliasLink}>
                            {event.userAlias || ANONYMOUS}
                        </Link>
                    </span>
                    <span className={s.messageText}>{t('events.started') + `x${event.count}`}</span>
                </>;
            case EventKind.BUY_CURRENCY:
                return <>
                    <span className={s.messageUserAlias}>
                        <Link to={`/supporters/${event.userId}`} className={s.messageUserAliasLink}>
                            {event.userName || ANONYMOUS}
                        </Link>
                    </span>
                    <span className={s.messageText}>{t('events.purchased')}</span>
                </>;
            case EventKind.MODERATION_STATUS:
                return <>
                    <span className={s.messageUserAlias}>{t('events.your-sticker')}</span>
                    <span className={s.messageText}>
                        {event.status === ModerationState.APPROVED
                            ? t('events.approved')
                            : event.status === ModerationState.DELETED ? t('events.deleted') : t('events.rejected')
                        }
                    </span>
                </>;
            case EventKind.WELCOME_BONUS_EARNED:
                return <>
                    <span className={s.messageUserAlias}>
                        <Link to={`/supporters/${event.userId}`} className={s.messageUserAliasLink}>
                            {event.userName || ANONYMOUS}
                        </Link>
                    </span>
                    <span className={s.messageText}>{t('events.earned-bonus')}</span>
                </>;
            case EventKind.BONUS_EARNED:
                return <>
                    <span className={s.messageUserAlias}>
                        <Link to={`/supporters/${event.userId}`} className={s.messageUserAliasLink}>
                            {event.userName || ANONYMOUS}
                        </Link>
                    </span>
                    <span className={s.messageText}>
                        {t('events.earned-bonus')}
                    </span>
                </>;
            case EventKind.BONUS_ISSUED:
                return <>
                    <span className={s.messageUserAlias}>
                        <Link to={`/supporters/${event.userId}`} className={s.messageUserAliasLink}>
                            {event.userName || ANONYMOUS}
                        </Link>
                    </span>
                    <span className={s.messageText}>
                        {t('events.sent-bonus')}
                    </span>
                </>;
            default:
                return '';
        }
    };

    const getSubject = () => {
        switch (event.kind) {
            case EventKind.STICKER_SENT:
                return <span className={s.messageStickerName}>{event.stickerName ?? NO_NAME}</span>;
            case EventKind.MEME_CANNON_STICKER_SENT:
                return <span className={s.messageStickerName}>{event.stickerName ?? NO_NAME}</span>;
            case EventKind.BUY_CURRENCY:
                return <span className={s.messagePackDescription}>{event.packDescription}</span>;
            case EventKind.MODERATION_STATUS:
                return <span className={s.messageStickerName}>{event.stickerName ?? NO_NAME}</span>;
            case EventKind.WELCOME_BONUS_EARNED:
            case EventKind.BONUS_EARNED:
            case EventKind.BONUS_ISSUED:
                return <span className={s.messagePackDescription}>
                    {currentUser
                        ? `${event.bonusValue} ${declensionUserCurrency(event.bonusValue, currentUser).toLowerCase()}`
                        : ''
                    }
                </span>;
            default:
                return '';
        }
    };

    const getMessageText = () => {
        if (event.kind === EventKind.STICKER_SENT || event.kind === EventKind.MEME_CANNON_STICKER_SENT) {
            return <span className={s.messageText}>{event.message}</span>;
        } else if (event.kind === EventKind.MODERATION_STATUS) {
            return <span className={s.messageText}>
                {event.status === ModerationState.DECLINED || event.status === ModerationState.DELETED
                    ? event.reason
                    : event.status === ModerationState.APPROVED ? t('events.you-can-send') : ''
                }
            </span>;
        }
    };

    useEffect(() => {
        const rightNow = Date.now();
        const diff = rightNow - event.timestamp;

        if (diff < ONE_SECOND) {
            setEventDateTime(getDateTimeString(event));
            setIsHighlighted(true);
            eventRef.current = event;
            timer = setTimeout(() => {
                setEventDateTime(getDateTimeString(eventRef.current));
                setIsHighlighted(false);
            }, ONE_SECOND - diff);
        }

        return () => clearTimeout(timer);
    }, [event]);

    const eventItemClassnames = cn(
        s.eventItem,
        {
            [s.eventItemHighlighted]: isHighlighted,
        },
        className,
    );

    const eventItemIconClassnames = s[`messageIcon${getIconColor()}`];

    return (
        <Row padding={10} columnGap={10} alignment={ERowAlignment.STRETCH} className={eventItemClassnames}>
            <Column padding={{ top: 5 }} justification={EColumnJustification.TOP}>
                <Icon
                    className={eventItemIconClassnames}
                    icon={getIcon()}
                    size={EComponentSize.EXTRA_SMALL}
                />
            </Column>
            <Column grow alignment={EColumnAlignment.STRETCH}>
                <Row margin={{ bottom: 3 }} style={{ display: 'block' }}>
                    {getUserAndAction()}
                </Row>
                <Row margin={{ bottom: 3 }}>
                    {getSubject()}
                </Row>
                <Row>
                    {getMessageText()}
                </Row>
                <Row margin={{ top: 10 }}>
                    { (event.kind === EventKind.STICKER_SENT || event.kind === EventKind.MEME_CANNON_STICKER_SENT) &&
                        <Row columnGap={10}>
                            <Column>
                                { isBanInProcess
                                    ? <Loader size={EComponentSize.EXTRA_SMALL} />
                                    : <div
                                        onClick={onPoopButtonClick}
                                        className={cn(s.button, { [s.buttonPoopActive]: event.isStickerDisabled })}
                                    >
                                        {event.isStickerDisabled ? <IconPoopSolid /> : <IconPoopLine/>}
                                    </div>
                                }
                            </Column>
                            <Column>
                                { isReplayInProcess
                                    ? <Loader size={EComponentSize.EXTRA_SMALL} />
                                    : <div onClick={onReplayButtonClick} className={s.button}>
                                        <IconReplayLine/>
                                    </div>
                                }
                            </Column>
                        </Row>
                    }
                    <Column grow alignment={EColumnAlignment.END} className={s.dateTime}>
                        {eventDateTime}
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};
