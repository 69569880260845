import React from 'react';
import { RouteObject } from 'react-router-dom';

import { mergeObjectArrays } from '@common';
import { PersonalArea, StreamerSettings, Privacy, Support, Terms, BasicContent } from '@memealerts/views';
import { routing as baseRouting } from '@routing';
import { AuthRequired, InviteRequired } from '@routing/guards';
import { ChannelRouting, DashboardRoutes, EventsRoutes, PersonalAreaRoutes, SupportersRoutes } from '@routing/routes';
import { CreateSticker, EventsPage, InvitePrompt, PersonalSettings } from '@views';

export const memeAlertsRouting = mergeObjectArrays<RouteObject>(
    baseRouting,
    [
        {
            path: '/channel',
            element: <AuthRequired>
                <InviteRequired invitePrompt={
                    <BasicContent>
                        <InvitePrompt/>
                    </BasicContent>
                }>
                    <StreamerSettings noRightSidebar/>
                </InviteRequired>
            </AuthRequired>,
            children: [...ChannelRouting],
        },
        {
            path: '/dashboard',
            element: <AuthRequired>
                <PersonalArea noRightSidebar/>
            </AuthRequired>,
            children: [...DashboardRoutes],
        },
        {
            path: '/events',
            element: <AuthRequired>
                <InviteRequired invitePrompt={
                    <BasicContent>
                        <InvitePrompt/>
                    </BasicContent>
                }>
                    <PersonalArea/>
                </InviteRequired>
            </AuthRequired>,
            children: [...EventsRoutes],
        },
        {
            path: '/sticker/create',
            element: <BasicContent><CreateSticker/></BasicContent>,
        },
        {
            path: '/stickers',
            element: <PersonalArea/>,
            children: [...PersonalAreaRoutes],
        },
        {
            path: '/supporters',
            element: <PersonalArea/>,
            children: [...SupportersRoutes],
        },
        {
            path: '/all-events',
            element: <AuthRequired allowUseObsToken><EventsPage/></AuthRequired>,
        },
        {
            path: 'terms',
            element: <Terms/>,
        },
        {
            path: 'support',
            element: <Support/>,
        },
        {
            path: 'privacy',
            element: <Privacy/>,
        },
        {
            path: '/settings',
            element: <AuthRequired><BasicContent><PersonalSettings/></BasicContent></AuthRequired>,
        },
    ],
    'path',
);
