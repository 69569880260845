import { Column } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { columnizeStickers, DEFAULT_SOUND_VOLUME } from '@common';
import { ISticker, SizeCalculationBase } from '@typings';
import { Sticker } from '@views';

import { StickerOverlay } from './components';
import s from './StickersGrid.scss';

interface StickersGridProps {
    className?: string,
    isAppleMobileDevice?: boolean,
    isMobileLayoutRecommended?: boolean,
    stickers: Array<ISticker>;
    stickersSoundEnabled?: boolean;
    columnsCount?: number;
    defaultSoundVolume?: number;
    onStickerClick?: (a: ISticker) => void;
    onSwitchSound?: () => void;
}

export const StickersGrid = (props: StickersGridProps) => {
    const {
        className,
        isAppleMobileDevice,
        isMobileLayoutRecommended,
        stickers,
        stickersSoundEnabled = false,
        columnsCount = 4,
        defaultSoundVolume = DEFAULT_SOUND_VOLUME,
        onStickerClick = noop,
    } = props;

    const [stickersColumnsCount, setStickersColumnsCount] = useState(0);

    const columns = columnizeStickers(stickers, stickersColumnsCount);

    const onStickerClickHandler = async (s: ISticker) => {
        onStickerClick(s);
    };

    const items = columns?.map((column, columnIdx) => (
        <Column rowGap={5} key={columnIdx} className={s.stickersGridColumn}>
            { column.map((sticker) => (
                <Sticker
                    key={sticker.id}
                    sticker={sticker}
                    withSound={stickersSoundEnabled}
                    soundOnHover
                    loop
                    useFadeInOnLoaded
                    useSkeletonOnLoading
                    useFallbackVideo={isAppleMobileDevice}
                    useAnimatedPreview={isMobileLayoutRecommended}
                    overlay={ !isMobileLayoutRecommended ? <StickerOverlay sticker={sticker}/> : undefined}
                    volume={sticker.customSettings?.customVolume || defaultSoundVolume}
                    sizeCalculationBase={SizeCalculationBase.WIDTH}
                    onClick={onStickerClickHandler}
                />
            ))}
        </Column>
    ));

    useEffect(() => {
        setStickersColumnsCount(isMobileLayoutRecommended ? 2 : columnsCount);
    }, [columnsCount, isMobileLayoutRecommended]);

    return (
        <div className={cn(s.stickersGrid, className)}>
            {items}
        </div>
    );
};
