import { useMatch } from 'react-router-dom';

import { useCurrentUser } from '@hooks';

import { useCatalogue } from './Catalogue';
import { useChannel } from './Channel';
import { useDashboard } from './Dashboard';
import { useFavorites } from './Favorites';
import { useMine } from './Mine';
import { useSearch } from './Search';
import { useSupporters } from './Supporters';

export const usePersonalArea = () => {
    const { currentUser } = useCurrentUser();

    return {
        catalogue: useCatalogue({ active: !!useMatch('/stickers') }),
        channel: useChannel({ active: !!useMatch('/stickers/channel') }),
        dashboard: useDashboard({ active: !!useMatch('/dashboard'), currentUser }),
        favorites: useFavorites({ active: !!useMatch('/stickers/favorites') }),
        mine: useMine({ active: !!useMatch('/stickers/mine') }),
        search: useSearch({ active: !!useMatch('/stickers/search') }),
        supporters: useSupporters({ active: !!useMatch('/supporters') }),
    };
};
