import { api } from '@store';

export const useTelegram = () => {
    const {
        telegram: {
            useLazyTelegramBotRegisterQuery,
            useLazyTelegramBotAuthQuery,
        },
    } = api;

    const [registerRequest] = useLazyTelegramBotRegisterQuery();
    const [authRequest] = useLazyTelegramBotAuthQuery();

    const registerLoginRequest = (queryString: string) => {
        return registerRequest(queryString).unwrap();
    };

    const authLoginRequest = (queryString: string) => {
        return authRequest(queryString).unwrap();
    };

    return {
        registerLoginRequest,
        authLoginRequest,
    };
};
