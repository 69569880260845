// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StickersGrid__stickersGrid--qNuSH {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  margin-top: 5px;
}
@media screen and (max-width: 960px) {
  .StickersGrid__stickersGrid--qNuSH {
    column-gap: 3px;
  }
}
.StickersGrid__stickersGridColumn--kYAGd {
  min-width: 24.4%;
  max-width: 24.4%;
  row-gap: 5px !important;
}
@media screen and (max-width: 960px) {
  .StickersGrid__stickersGridColumn--kYAGd {
    min-width: 49.5%;
    max-width: 49.5%;
    row-gap: 3px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/base/views/fragments/StickersGrid/StickersGrid.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AACJ;AACI;EANJ;IAOQ,eAAA;EAEN;AACF;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,uBAAA;AAER;AAAQ;EALJ;IAMQ,gBAAA;IACA,gBAAA;IACA,uBAAA;EAGV;AACF","sourcesContent":[".stickersGrid {\r\n    display: flex;\r\n    flex-direction: row;\r\n    column-gap: 5px;\r\n    margin-top: 5px;\r\n\r\n    @media screen and (max-width: 960px) {\r\n        column-gap: 3px;\r\n    }\r\n\r\n    &Column {\r\n        min-width: 24.4%;\r\n        max-width: 24.4%;\r\n        row-gap: 5px !important;\r\n\r\n        @media screen and (max-width: 960px) {\r\n            min-width: 49.5%;\r\n            max-width: 49.5%;\r\n            row-gap: 3px !important;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickersGrid": `StickersGrid__stickersGrid--qNuSH`,
	"stickersGridColumn": `StickersGrid__stickersGridColumn--kYAGd`
};
export default ___CSS_LOADER_EXPORT___;
