import { parseBool } from '@common';
import { useApi } from '@hooks';
import { IHookBaseParams } from '@typings';

interface ISystemSettingsParams extends IHookBaseParams {}

export const useSystemSettings = (params: ISystemSettingsParams) => {
    const { active } = params;
    const {
        settings: {
            useLoadSettingsQuery,
        },
    } = useApi();

    const {
        data: settings,
        isSuccess,
    } = useLoadSettingsQuery(void(0), { skip: !active });

    const asString = (name: string) => settings?.find((s) => s.name === name)?.value ?? '';
    const asBool = (name: string) => parseBool(settings?.find((s) => s.name === name)?.value ?? 'false');
    const asInt = (name: string) => parseInt(settings?.find((s) => s.name === name)?.value ?? '0');
    const asFloat = (name: string) => parseFloat(settings?.find((s) => s.name === name)?.value ?? '0');

    return {
        isLoaded: isSuccess,
        asBool,
        asFloat,
        asInt,
        asString,
    };
};
