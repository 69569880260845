import { Button, EComponentColor, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import s from './AssetsControl.scss';
import { useAssetsControl } from './hooks';
import { IAssetsControlProps } from './types';
import { EPreviewSize, Preview } from '../Preview';

export const AssetsControl = (props: IAssetsControlProps) => {
    const {
        assets,
        selectedAssets,
        assetsCategories,
        layerPositions,
        assetsFaces,
        onChangeAssetsList,
    } = props;
    const {
        face,
        faceEmotion,
        selected,
        onItemClick,
    } = useAssetsControl({
        assetsFaces,
        selectedAssets,
        onChangeAssetsList,
    });
    const { t: translation } = useTranslation();
    const { closeModal } = useModals();

    const tc = (id: string) => translation(`common.${id}`);

    const handleItemClick = (id: string) => () => onItemClick(id);

    const handleOkClick = () => closeModal();

    return (
        <div>
            <div className={s.list}>
                {selectedAssets.map((asset) => (
                    <div
                        key={asset.id}
                        className={cn(s.item, { [s.itemSelected]: selected.includes(asset.id) })}
                        onClick={handleItemClick(asset.id)}
                    >
                        <div className={s.itemInner}>
                            <Preview
                                className={s.preview}
                                assets={assets}
                                displayedAssets={[
                                    asset,
                                ]}
                                assetsCategories={assetsCategories}
                                layerPositions={layerPositions}
                                face={face}
                                faceEmotion={faceEmotion}
                                size={EPreviewSize.M}
                                hiddenLayerPositions={[]}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <Button
                color={EComponentColor.ACCENT}
                caption={tc('ok')}
                onClick={handleOkClick}
            />
        </div>
    );
};
