
import React from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { Avatar } from '@components';
import { IPosition } from '@typings';

import s from './AvatarGroup.scss';


interface IAvatar {
    size: number;
    position?: IPosition;
    zIndex: number;
}

interface IAvatarGroupProps {
    avatars: IAvatar[];
    source: string;
}

interface PredefinedGroupProps {
    source: string;
}

export const AvatarGroup = (props: IAvatarGroupProps) => {
    const {
        avatars = [],
        source = ImageDefaultCurrency,
    } = props;

    const items = avatars.map((a, idx) => (
        <Avatar
            key={idx}
            className={s.avatar}
            source={source || ImageDefaultCurrency}
            fallbackImage={ImageDefaultCurrency}
            rounded
            width={a.size}
            height={a.size}
            style={{
                left: a.position?.x,
                top: a.position?.y,
                zIndex: a.zIndex,
            }}
        />
    ));

    return (
        <div className={s.avatarGroupContainer}>
            {items}
        </div>
    );
};

export const SimpleSmallGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 30, zIndex: 1 },
]}/>;

export const SimpleMediumGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 40, zIndex: 1 },
]}/>;

export const TripleGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 40, zIndex: 1, position: { x: 0, y: -20 } },
    { size: 30, zIndex: 1, position: { x: -20, y: -10 } },
    { size: 20, zIndex: 1, position: { x: -35, y: 0 } },
]}/>;

export const TripleAltGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 30, zIndex: 1, position: { x: 10, y: -15 } },
    { size: 30, zIndex: 1, position: { x: -40, y: -15 } },
    { size: 40, zIndex: 1 },
]}/>;

export const FourGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 30, zIndex: 1, position: { x: -15, y: -25 } },
    { size: 30, zIndex: 1, position: { x: -40, y: 0 } },
    { size: 30, zIndex: 1, position: { x: 10, y: 0 } },
    { size: 30, zIndex: 1, position: { x: -15, y: 0 } },
]}/>;

export const FlowerGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 20, zIndex: 1, position: { x: -10, y: -28 } },
    { size: 20, zIndex: 1, position: { x: -10, y: 8 } },
    { size: 20, zIndex: 1, position: { x: -25, y: -19 } },
    { size: 20, zIndex: 1, position: { x: -25, y: -1 } },
    { size: 20, zIndex: 1, position: { x: 5, y: -19 } },
    { size: 20, zIndex: 1, position: { x: 5, y: -1 } },
    { size: 40, zIndex: 1, position: { x: -20, y: -20 } },
]}/>;

export const HugeHeapGroup = (props: PredefinedGroupProps) => <AvatarGroup source={props.source} avatars={[
    { size: 30, zIndex: 1, position: { x: -18, y: -30 } },
    { size: 30, zIndex: 1, position: { x: 5, y: -20 } },
    { size: 30, zIndex: 1, position: { x: -40, y: -25 } },
    { size: 30, zIndex: 1, position: { x: -30, y: -10 } },
    { size: 20, zIndex: 1, position: { x: -45, y: -10 } },
    { size: 40, zIndex: 1, position: { x: -20, y: -20 } },
    { size: 40, zIndex: 1, position: { x: 0, y: -10 } },
]}/>;
