import { ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { Header as LandingHeader } from '@memealerts/views/pages/Landing/components';
import { Header as PersonalAreaHeader } from '@memealerts/views/templates/PersonalAreaPage/components';
import { DesktopPage, MobilePage } from '@views';

interface IInformationPageProps extends TApp {}

export const BasicPage = (props: React.PropsWithChildren<IInformationPageProps>) => {
    const {
        children,
        currentUser: {
            noCurrentUser,
        },
        deviceInfo: {
            isMobileLayoutRecommended,
        },
    } = props;

    const { forceUpdateTheme } = useContext(ThemeContext);

    useLayoutEffect(() => {
        forceUpdateTheme();
    }, []);

    return (
        isMobileLayoutRecommended
            ? <MobilePage>
                {children}
            </MobilePage>
            : <DesktopPage
                header={noCurrentUser ? <LandingHeader {...props} />: <PersonalAreaHeader {...props}/>}
                footer={<Footer {...props}/>}
            >
                {children}
            </DesktopPage>
    );
};
