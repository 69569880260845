import { Column, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import s from './CurrencyPack.scss';
import { FlowerGroup, FourGroup, HugeHeapGroup, SimpleMediumGroup, SimpleSmallGroup, TripleAltGroup, TripleGroup } from '../AvatarGroup';

export interface ICurrencyPackProps {
    badgeText?: string;
    currencyImage: string;
    hideCurrencyPicture?: boolean;
    isAction?: boolean;
    isBonus?: boolean;
    isFullwidth?: boolean;
    isMobileLayoutRecommended?: boolean;
    isPopular?: boolean;
    isSelected?: boolean;
    oldPrice?: number;
    price: number;
    value: number;
    valueText: string;
    onClick?: () => void;
}

const roubleSign = '₽';

export const CurrencyPack = (props: ICurrencyPackProps) => {
    const {
        isMobileLayoutRecommended,
        isAction = false,
        isFullwidth = false,
        isPopular = false,
        isBonus = false,
        badgeText,
        oldPrice,
        price,
        value,
        valueText,
        isSelected = false,
        currencyImage,
        hideCurrencyPicture,
        onClick,
    } = props;

    let picture: JSX.Element;

    if (value > 0 && value <= 10) {
        picture = <SimpleSmallGroup source={currencyImage}/>;
    } else if (value > 10 && value <= 25) {
        picture = <SimpleMediumGroup source={currencyImage}/>;
    } else if (value > 25 && value <= 75) {
        picture = <TripleGroup source={currencyImage}/>;
    } else if (value > 75 && value <= 200) {
        picture = <TripleAltGroup source={currencyImage}/>;
    } else if (value > 200 && value <= 400) {
        picture = <FourGroup source={currencyImage}/>;
    } else if (value > 400 && value <= 800) {
        picture = <FlowerGroup source={currencyImage}/>;
    } else {
        picture = <HugeHeapGroup source={currencyImage}/>;
    }

    return (
        <div
            className={cn(
                s.currencyPack,
                {
                    [s.currencyPackMobileLayout]: isMobileLayoutRecommended,
                    [s.currencyPackMobileLayoutNoIcons]: isMobileLayoutRecommended && hideCurrencyPicture,
                    [s.currencyPackSelected]: isSelected,
                    [s.currencyPackFullWidth]: isFullwidth,
                    [s.currencyPackBonus]: isBonus,
                    [s.currencyPackBonusMobileLayout]: isBonus && isMobileLayoutRecommended,
                },
            )}
            onClick={onClick}
        >
            <Column
                className={cn(
                    s.currencyPackContent,
                    {
                        [s.currencyPackContentFullWidth]: isFullwidth,
                    },
                )}
            >
                { isFullwidth &&
                <>
                    <Row className={s.currencyPackContentPictureRow}>
                        {picture}
                    </Row>
                    <Row className={s.currencyPackContentValueRow}>
                        <div>{value}</div>
                        <div className={s.currencyPackContentValueTextRow}>{valueText}</div>
                    </Row>
                    <Row className={s.currencyPackContentPriceRow}>
                        <div className={s.oldPrice}>
                            <div>{oldPrice}</div>
                            <div>{roubleSign}</div>
                        </div>
                        <div>{price}</div>
                        <div>{roubleSign}</div>
                    </Row>
                </>
                }

                {!isFullwidth &&
                <>
                    {!hideCurrencyPicture &&
                        <Row className={s.currencyPackContentPictureRow}>
                            {picture}
                        </Row>
                    }
                    <Row className={s.currencyPackContentValueRow}>
                        {value}
                    </Row>
                    <Row className={s.currencyPackContentValueTextRow}>
                        {valueText}
                    </Row>
                    <Row className={s.currencyPackContentPriceRow}>
                        <div>{price}</div>
                        <div>{roubleSign}</div>
                    </Row>
                </>
                }

                { (isPopular || isAction || isBonus) &&
                    <div className={cn(
                        s.badge,
                        {
                            [s.badgeAction]: isAction,
                            [s.badgePopular]: isPopular,
                            [s.badgeBonus]: isBonus,
                        },
                    )}>
                        <div className={s.badgeText}>
                            {badgeText}
                        </div>
                    </div>
                }

            </Column>
        </div>
    );
};
