import {
    ImageAfoninabondLogoMini,
    ImageHappyZerGLogoMini,
    ImageOlyashaaLogoMini,
    ImageRomanovaleraLogoMini,
    ImageRurualiceLogoMini,
} from '@assets/images/png';
import { AfoninabondVideo, HappyZerGVideo, OlyashaaVideo, RomanovaleraVideo, RurualiceVideo } from '@assets/videos';

import { IVideoData } from '../typings';

export const VIDEO_DATA: Array<IVideoData> = [
    {
        src: AfoninabondVideo,
        logo: ImageAfoninabondLogoMini,
        name: 'Afoninabond',
        followers: `4.2K`,
        externalLink: 'https://www.twitch.tv/afoninabond',
    },
    {
        src: HappyZerGVideo,
        logo: ImageHappyZerGLogoMini,
        name: 'HappyZerG',
        followers: `71.9K`,
        externalLink: 'https://www.twitch.tv/HappyZerG',
    },
    {
        src: RomanovaleraVideo,
        logo: ImageRomanovaleraLogoMini,
        name: 'Romanovalera',
        followers: `78.3K`,
        externalLink: 'https://www.twitch.tv/romanovalera',
    },
    {
        src: OlyashaaVideo,
        logo: ImageOlyashaaLogoMini,
        name: 'Olyashaa',
        followers: `701K`,
        externalLink: 'https://www.twitch.tv/olyashaa',
    },
    {
        src: RurualiceVideo,
        logo: ImageRurualiceLogoMini,
        name: 'RuruAlice',
        followers: `1.3K`,
        externalLink: 'https://www.twitch.tv/RuruAlice',
    },
];
