import { t } from 'i18next';

import { IUser } from '@typings';


const declension = (forms: string[], val: number) => {
    const cases = [2, 0, 1, 1, 1, 2];

    return forms[(val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5]];
};

export const declensionRubles = (val: number) => {
    return declension(['рубль', 'рубля', 'рублей'], val);
};

export const declensionUserCurrency = (val: number, user?: IUser) => {
    if (!user) return '';

    const currencyNameDeclensions = user.channel.currencyNameDeclensions;
    const accusative = user.channel.currencyName;
    const genitive = currencyNameDeclensions?.genitive;
    const mGenitive = currencyNameDeclensions?.multiple.genitive;

    return declension([accusative, genitive, mGenitive] as string[], Number(val));
};

export const declensionUserSupporter = (val: number, user?: IUser) => {
    if (!user) return '';

    const supporterNameDeclensions = user?.channel.supporterNameDeclensions;
    const accusative = user?.channel.supporterName;
    const genitive = supporterNameDeclensions?.genitive;
    const mGenitive = supporterNameDeclensions?.multiple.genitive;

    return declension([accusative, genitive, mGenitive] as string[], Number(val));
};

export const roubleValueText = (value: number, upperCamelCase = false) => {
    const rem = value % 10;
    let text = 'рубль';

    if (rem === 0) {
        text = 'рублей';
    } else if (rem === 1) {
        text = 'рубль';
    } else if (rem >= 5) {
        text = 'рублей';
    } else {
        text = 'рубля';
    }

    return upperCamelCase ? text.charAt(0).toUpperCase() + text.substring(1) : text;
};

export const supporterValueText = (value: number, upperCamelCase = false) => {
    const rem = value % 10;
    let text = 'саппортер';

    if (rem === 0) {
        text = t('common.of-supporters').toLowerCase();
    } else if (rem === 1) {
        text = t('common.supporter').toLowerCase();
    } else if (rem >= 5) {
        text = t('common.of-supporters').toLowerCase();
    } else {
        text = t('common.of-supporter').toLowerCase();
    }

    return upperCamelCase ? text.charAt(0).toUpperCase() + text.substring(1) : text;
};

export const currencyValueText = (sum: number, lang: string) => {
    return lang !== 'en'
        ? `${Math.round(sum)} ${roubleValueText(Math.round(sum))}`
        : `$${Math.round(sum)}`;
};
