// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaceList__root--cvsoX {
  display: flex;
  flex-flow: wrap;
  width: 645px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/PersonalSettings/components/AvatarEditor/components/FaceList/FaceList.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".root {\r\n    display: flex;\r\n    flex-flow: wrap;\r\n    width: 645px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FaceList__root--cvsoX`
};
export default ___CSS_LOADER_EXPORT___;
