import { Column, Row } from '@hyperclap/ui';
import React from 'react';

import { useApp } from '@hooks';
import { InformationPage } from '@memealerts/views';

import s from './Support.scss';

export const Support = () => {
    const app = useApp();

    return (
        <InformationPage {...app}>
            <Column className={s.contentArea}>
                <Row className={s.title}>{'ПОДДЕРЖКА'}</Row>
                <Row>
                    <Column>
                        <p>
                            Если у вас возникли какие-то сложности с сервисом, вы можете<br/>
                            связаться с нашей технической поддержкой по адресу:
                            <a className={s.link} href="mailto:support@memealerts.com">support@memealerts.com</a>
                        </p>

                        <p>
                            Внимание!
                            Пожалуйста всегда присылайте ваш id (вы можете найти его в Настройках в боковом меню).
                        </p>

                        <p>
                            Если вопрос касается зачисления коинов присылайте в письме:
                        </p>
                        <ul>
                            <li>Ваш id</li>
                            <li>Ссылку на MemeAlerts стримера у которого вы покупали коины</li>
                            <li>Количество коинов</li>
                            <li>Дату и время</li>
                            <li>Чек об оплате</li>
                        </ul>
                    </Column>
                </Row>
            </Column>
        </InformationPage>
    );
};
