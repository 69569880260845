import { AppVersion } from '@typings';

import { baseApi } from './base.api';

export const tasksApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getVersion: build.query<AppVersion, void>({
            query: () => ({
                url: 'assets/json/version.json',
                method: 'GET',
            }),
        }),
    }),
});
