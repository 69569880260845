import { IAvatarAssetFace, IAvatarAssetSkinColor } from '@typings';

interface IUseAssetListItemParams {
    assetsFaces: IAvatarAssetFace[];
    assetsSkinColors: IAvatarAssetSkinColor[];
}

export const useAssetListItem = (params: IUseAssetListItemParams) => {
    const {
        assetsFaces,
        assetsSkinColors,
    } = params;
    const defaultFace = assetsFaces[0];
    const defaultFaceEmotion = defaultFace?.emotions?.[0];
    const skinColor = assetsSkinColors[2];

    return {
        defaultFace,
        defaultFaceEmotion,
        skinColor,
    };
};
