import { Column, EColumnAlignment, EColumnJustification, EComponentSize, Label, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { DogLoader } from '@views';

import s from './BusyBox.scss';

interface IBusyBoxProps {
    caption: string;
    withBorder?: boolean;
}

export const BusyBox = ({ caption = 'Ожидание', withBorder }: IBusyBoxProps) => {
    return (
        <Column
            margin={{ top: 150 }}
            rowGap={20}
            alignment={EColumnAlignment.CENTER}
            justification={EColumnJustification.CENTER}
            className={cn({ [s.withBorder]: withBorder })}
        >
            <Row>
                <DogLoader size={170}/>
            </Row>
            <Label height={25} caption={caption} size={EComponentSize.MEDIUM} muted/>
        </Column>
    );
};
