import { IAvatarAssetFace, IAvatarAssetFaceEmotion, IAvatarAssetFaceEmotionCategory } from '@typings';

export const getDefaultFaceEmotion = (
    assetsFacesEmotionsCategories: IAvatarAssetFaceEmotionCategory[],
    face: IAvatarAssetFace,
) => {
    const happyFaceEmotionCategory = assetsFacesEmotionsCategories.find((item) => item.title.includes('Happy'));

    return face.emotions.find((item) => item.categoryId === happyFaceEmotionCategory?.id) as IAvatarAssetFaceEmotion;
};
