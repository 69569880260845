// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventsList__scrollableContentArea--wZOca {
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/PersonalArea/components/Dashboard/components/EventsList/EventsList.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ","sourcesContent":[".scrollableContentArea {\r\n    padding: 0 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollableContentArea": `EventsList__scrollableContentArea--wZOca`
};
export default ___CSS_LOADER_EXPORT___;
