import { Column, EComponentSize, Label, Row } from '@hyperclap/ui';
import React from 'react';
import { TooltipProps } from 'recharts';

import s from './CustomTooltip.scss';

interface ICustomTooltipProps extends TooltipProps<number, string> {
    language: string;
}

export const CustomTooltip = (props: ICustomTooltipProps) => {
    const {
        active,
        payload,
        label,
        language,
    } = props;

    return active
        ? (
            <Column padding={10} rowGap={5} className={s.customTooltip}>
                <Row>
                    <Label padding={0} caption={label} size={EComponentSize.SMALL} useBoldFont/>
                </Row>
                <Row>
                    {`${payload?.[0]?.value} ${language === 'en' ? '$' : '₽'}`}
                </Row>
            </Column>
        )
        : null;
};
